import React from 'react'
import {
  Box,
  Modal as ChakraModal,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  children: any
  onClose: () => void
  title?: string
  size?: string
}

const Modal = ({ isOpen, children, onClose, title, size }: Props) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} size={size ?? 'lg'} isCentered>
      <ModalOverlay bg="blackAlpha.600" />
      <ModalContent
        p="1px"
        bg="linear-gradient(135deg, #222222 50%, #626262 100%)"
        borderRadius="3xl"
      >
        <Box bg="linear-gradient(135deg, #121212 50%, #181818 100%)" borderRadius="3xl" p={4}>
          <ModalHeader>
            {title && <Heading size="md">{title}</Heading>}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody py={2}>{children}</ModalBody>
        </Box>
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
