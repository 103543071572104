import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import { injectedWallet } from '@rainbow-me/rainbowkit/wallets'
import { Chain, configureChains, createConfig } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

const nordek: Chain = {
  id: 81041,
  name: 'Nordek',
  network: 'nordek',
  nativeCurrency: {
    decimals: 18,
    name: 'Nordek',
    symbol: 'NRK',
  },
  rpcUrls: {
    public: { http: ['https://mainnet-rpc.nordekscan.com'] },
    default: { http: ['https://mainnet-rpc.nordekscan.com'] },
  },
  blockExplorers: {
    default: { name: 'Nordekscan', url: 'https://nordekscan.com:443' },
  },
  testnet: false,
}

const availableChains: Chain[] = [nordek]

const { chains, publicClient } = configureChains(availableChains, [
  jsonRpcProvider({
    rpc: chain => {
      switch (chain.id) {
        case nordek.id:
          return { http: 'https://mainnet-rpc.nordekscan.com' }
        default:
          return null
      }
    },
  }),
])

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [injectedWallet({ chains })],
  },
])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig
  }
}

export { chains, wagmiConfig }
