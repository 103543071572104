import type { SVGProps } from 'react'
const SvgIconTransactions = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={30} fill="none" {...props}>
    <path
      fill="#fff"
      d="M18.126 13.311a6.956 6.956 0 0 0-6.948 6.948 6.956 6.956 0 0 0 6.948 6.948 6.956 6.956 0 0 0 6.948-6.948 6.956 6.956 0 0 0-6.948-6.948m2.362 7.854h-2.362a.907.907 0 0 1-.907-.906V17.8a.907.907 0 0 1 1.813 0v1.553h1.456a.907.907 0 0 1 0 1.812m-1.154-17.52H7.251a3.32 3.32 0 0 0-3.323 3.322v15.709a3.32 3.32 0 0 0 3.323 3.323h4.277a8.64 8.64 0 0 1-2.163-5.74c0-4.833 3.927-8.76 8.76-8.76 1.668 0 3.215.459 4.532 1.28V6.968a3.32 3.32 0 0 0-3.323-3.322M9.667 16.332H8.46a.913.913 0 0 1-.906-.906c0-.496.41-.906.906-.906h1.208c.496 0 .907.41.907.906 0 .495-.411.906-.907.906m3.625-3.625H8.46a.913.913 0 0 1-.906-.906c0-.496.41-.906.906-.906h4.833c.496 0 .907.41.907.906 0 .495-.411.906-.907.906m4.834-3.625H8.459a.913.913 0 0 1-.906-.906c0-.496.41-.906.906-.906h9.667c.495 0 .906.41.906.906 0 .495-.41.906-.906.906"
    />
  </svg>
)
export default SvgIconTransactions
