import { useConnectModal } from '@rainbow-me/rainbowkit'
import SvgWallet from 'assets/Wallet'
import { useAuth } from 'providers/AuthProvider'
import { useCallback } from 'react'
import NorpayButton from './NorpayButton'
import { useAccount } from 'wagmi'
import { useGlobal } from 'providers/GlobalProvider'

const AuthenticateButton = () => {
  const { isAuthenticated, account, authenticate, isAuthenticating } = useAuth()
  const { openConnectModal } = useConnectModal()
  const { isLoading: fetchingBasicInfo } = useGlobal()

  const { isConnecting } = useAccount()

  const onConnectWallet = useCallback(async () => {
    if (!account) {
      return openConnectModal?.()
    }

    if (!isAuthenticated) {
      return authenticate({ address: account })
    }
  }, [])

  return (
    <NorpayButton
      fontSize={18}
      lineHeight={5}
      fontFamily="Inter Variable"
      paddingBlock={4}
      leftIcon={<SvgWallet />}
      isLoading={isAuthenticating || isConnecting || fetchingBasicInfo}
      loadingText={fetchingBasicInfo ? 'Setting things up...' : 'Authenticating...'}
      spinnerPlacement="end"
      onClick={onConnectWallet}
    >
      Connect Wallet
    </NorpayButton>
  )
}

export default AuthenticateButton
