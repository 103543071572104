import React from 'react'
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableContainerProps,
} from '@chakra-ui/react'

export interface TableColumn<T> {
  render: (obj: T) => any
  title: string
}

interface Props<T> extends TableContainerProps {
  columns: TableColumn<T>[]
  data: T[]
  isVertical?: boolean
}

function TableComponent<T>({ isVertical, columns, data, ...rest }: Props<T>) {
  return (
    <TableContainer {...rest}>
      <ChakraTable>
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th
                key={column.title + index.toString()}
                border="none"
                textTransform="none"
                fontSize="none"
                textColor="white"
                pl={0}
              >
                {column.title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((record, index) => (
            <Tr key={index.toString()}>
              {columns.map(column => (
                <Td key={column.title} border="none" pl={0}>
                  {column.render(record)}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default TableComponent
