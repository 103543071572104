import {
  Box,
  Container,
  Divider,
  Grid,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { Warning } from 'components'
import NorpayButton from 'components/Button/NorpayButton'
import ApplyActivationRequiredCardModal from 'components/Modal/ApplyActivationRequiredCardModal'
import ApplycardModal from 'components/Modal/ApplyCardModal'
import RenderCard from 'components/cards/RenderCard'
import { cardIssuerType, cardTypeToDisplayName } from 'data/cardData'
import CardSwitcher from 'pages/Dashboard/components/CardSwitcher/CardSwitcher'
import { useGlobal } from 'providers/GlobalProvider'
import { useMemo, useState } from 'react'
import { CardName } from 'types/card'

export default function ApplyCard() {
  const { cardTypes, currentChain } = useGlobal()

  const token = currentChain?.tokens[0]

  const { isOpen, onToggle } = useDisclosure()
  const {
    isOpen: isActivationRequiredModalOpen,
    onOpen: openActivationRequiredCardModal,
    onClose: closeActivationRequiredCardModal,
  } = useDisclosure()
  const toast = useToast()

  const [cardTypeIndex, setCardTypeIndex] = useState<number>(0)

  const showNextCardType = () => {
    if (!cardTypes) return

    const nextCardTypeIndex = cardTypeIndex + 1
    const isLastCard = nextCardTypeIndex === cardTypes.length

    setCardTypeIndex(isLastCard ? 0 : nextCardTypeIndex)
  }

  const showPrevCardType = () => {
    if (!cardTypes) return

    const prevCardTypeIndex = cardTypeIndex - 1
    const isFirstCard = prevCardTypeIndex < 0

    setCardTypeIndex(isFirstCard ? cardTypes.length - 1 : prevCardTypeIndex)
  }

  const selectedCard = useMemo(() => {
    return cardTypes?.at(cardTypeIndex)
  }, [cardTypes, cardTypeIndex])

  const onApplyNow = async () => {
    if (selectedCard?.name === CardName.NorpayEURCard) {
      return toast({ status: 'info', title: 'Under Maintenance', colorScheme: 'purple' })
    }

    if (selectedCard?.applicationType === 'activation') {
      return openActivationRequiredCardModal()
    }

    onToggle()
  }

  return (
    <Box
      bgGradient="linear-gradient(0deg, rgba(9, 9, 9, 0.31) -18.95%, rgba(45, 45, 45, 0.31) 100%)"
      filter="drop-shadow(0px 32.194px 57.949px rgba(0, 0, 0, 0.35))"
      px={5}
      py={8}
      borderRadius={24}
      maxW={'520px'}
      marginInline="auto"
    >
      {(cardTypes?.length ?? 0) > 1 ? (
        <CardSwitcher
          showNextCardType={showNextCardType}
          showPrevCardType={showPrevCardType}
          display={'inline-flex'}
        >
          <Container maxW="430px">
            <RenderCard cardName={selectedCard?.name as CardName} hideInfo={false} />
          </Container>
        </CardSwitcher>
      ) : (
        <Container maxW="430px">
          <RenderCard cardName={selectedCard?.name as CardName} hideInfo={false} />
        </Container>
      )}

      <Box mt={12} as="section">
        <Heading
          as="h1"
          fontSize={20}
          fontFamily="Inter Variable"
          fontWeight={600}
          textAlign="center"
        >
          {cardTypeToDisplayName[selectedCard?.name ?? '']}
        </Heading>

        {selectedCard?.status === 'enabled' ? (
          <>
            <Divider my={5} borderColor="#404040" />

            <Grid gridTemplateColumns={'2fr 1fr'}>
              <Text fontFamily="Inter Variable" fontSize={12} color="#B3B3B3">
                Price
              </Text>
              <Text
                fontFamily="Inter Variable"
                fontSize={12}
                fontWeight={600}
                color="#F7F7F7"
                lineHeight={5}
                textAlign="right"
              >
                {parseFloat(selectedCard?.applicationFee ?? '0')}
              </Text>
            </Grid>

            <Divider my={4} borderColor="#404040" />

            <Grid gridTemplateColumns={'2fr 1fr'}>
              <Text fontFamily="Inter Variable" fontSize={12} color="#B3B3B3">
                Type
              </Text>
              <Text
                fontFamily="Inter Variable"
                fontSize={12}
                fontWeight={600}
                color="#F7F7F7"
                lineHeight={5}
                textAlign="right"
              >
                {cardIssuerType[selectedCard.name]}
              </Text>
            </Grid>

            <Divider my={4} borderColor="#404040" />

            <Grid gridTemplateColumns={'2fr 1fr'}>
              <Text fontFamily="Inter Variable" fontSize={12} color="#B3B3B3">
                Currency
              </Text>
              <Text
                fontFamily="Inter Variable"
                fontSize={12}
                fontWeight={600}
                color="#F7F7F7"
                lineHeight={5}
                textAlign="right"
              >
                {token?.symbol}
              </Text>
            </Grid>

            <Divider my={4} borderColor="#404040" />

            <Grid gridTemplateColumns={'2fr 1fr'}>
              <Text fontFamily="Inter Variable" fontSize={12} color="#B3B3B3">
                Daily Usage Limit
              </Text>
              <Text
                fontFamily="Inter Variable"
                fontSize={12}
                fontWeight={600}
                color="#F7F7F7"
                lineHeight={5}
                textAlign="right"
              >
                ${selectedCard.limitMaxBalance}
              </Text>
            </Grid>

            <NorpayButton w="full" mt={6} py={4} maxW="auto" onClick={onApplyNow}>
              Apply Now
            </NorpayButton>
          </>
        ) : (
          <>
            <Box mt={7} />

            <Warning
              textColor="orange.300"
              text={'This Card is currently under maintenance. Please select a different card'}
            />
          </>
        )}
      </Box>

      <ApplycardModal
        isOpen={isOpen}
        onClose={onToggle}
        selectedCardName={selectedCard?.name as CardName}
      />

      {selectedCard && (
        <ApplyActivationRequiredCardModal
          isOpen={isActivationRequiredModalOpen}
          onClose={closeActivationRequiredCardModal}
          selectedCardType={selectedCard}
        />
      )}
    </Box>
  )
}
