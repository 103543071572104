import React, { useEffect, useState } from 'react'
import { Button, Container, ContainerBox, Loader, Table } from 'components'
import { useApi } from 'hooks/useApi'
import { CreateDiscount, CreateDiscountBatch, Discount } from 'types/discount'
import { Box, Heading, useDisclosure } from '@chakra-ui/react'
import CreateDiscountModal from './CreateDiscountModal'
import CreateDiscountBatchModal from './CreateDiscountBatchModal'

const Discounts = () => {
  const { getDiscounts, deleteDiscount, createDiscount, createDiscountBatch, exportDiscounts } =
    useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [discounts, setDiscounts] = useState<Discount[]>([])
  const discountCreateModal = useDisclosure()
  const discountBatchCreateModal = useDisclosure()

  const getData = async () => {
    try {
      const discountData = await getDiscounts()
      setDiscounts(discountData)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const discountCreate = async (data: CreateDiscount) => {
    try {
      await createDiscount({
        ...data,
        dateFrom: new Date(data.dateFrom).toISOString(),
        dateTo: new Date(data.dateTo).toISOString(),
      })
      getData()
      discountCreateModal.onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const discountBatchCreate = async (data: CreateDiscountBatch) => {
    try {
      await createDiscountBatch({
        ...data,
        dateFrom: new Date(data.dateFrom).toISOString(),
        dateTo: new Date(data.dateTo).toISOString(),
      })
      getData()
      discountBatchCreateModal.onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const discountDelete = async (data: any) => {
    try {
      await deleteDiscount(data.id)
      getData()
    } catch (e) {
      console.error(e)
    }
  }

  const columns = [
    {
      title: 'Value',
      render: discount => `${discount.value}%`,
    },
    { title: 'Code', render: discount => discount.code },
    {
      title: 'Quantity',
      render: discount => discount.quantity,
    },
    {
      title: 'Remaining',
      render: discount => discount.remainingQuantity,
    },
    {
      title: 'Assigned To',
      render: discount => discount.assignedTo,
    },
    {
      title: 'Date From',
      render: discount => new Date(discount.dateFrom).toUTCString(),
    },
    {
      title: 'Date To',
      render: discount => new Date(discount.dateTo).toUTCString(),
    },
    {
      title: 'Action',
      render: discount => (
        <Box>
          <Button variant="link" onClick={() => discountDelete(discount)}>
            Delete
          </Button>
        </Box>
      ),
    },
  ]

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Container mt={16}>
        <ContainerBox my={12} py={6} px={{ base: 4, lg: 12 }}>
          <Heading mb={6}>Discounts</Heading>
          <Button onClick={discountCreateModal.onOpen} mr={6} mb={6}>
            Create Discount
          </Button>
          <Button onClick={discountBatchCreateModal.onOpen} mb={6} mr={6}>
            Create Discount Batch
          </Button>
          <Button variant="secondary" onClick={exportDiscounts} mb={6}>
            Export Discounts
          </Button>
          <Box mb={4} opacity={0.75}>
            Only non-batch discounts are displayed. Export discounts to view all.
          </Box>
          {discounts.length > 0 ? (
            <Table columns={columns} data={discounts} />
          ) : (
            <Box>No discounts created</Box>
          )}
        </ContainerBox>
      </Container>
      <CreateDiscountModal
        isOpen={discountCreateModal.isOpen}
        onClose={discountCreateModal.onClose}
        onCreate={discountCreate}
      />
      <CreateDiscountBatchModal
        isOpen={discountBatchCreateModal.isOpen}
        onClose={discountBatchCreateModal.onClose}
        onCreate={discountBatchCreate}
      />
    </>
  )
}

export default Discounts
