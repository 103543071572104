import {
  Box,
  Container,
  Heading,
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'

export default function ApplyCardLayout() {
  const location = useLocation()

  return (
    <Container>
      <Box
        as="section"
        borderRadius={24}
        borderColor="rgba(178, 178, 178, 0.18)"
        borderWidth={'1px'}
        bgGradient="linear-gradient(152deg, rgba(9, 9, 9, 0.61) -23.33%, rgba(30, 30, 30, 0.61) 16.1%, rgba(11, 11, 11, 0.61) 66.14%, rgba(33, 33, 33, 0.61) 195.42%, rgba(7, 7, 7, 0.61) 238.87%)"
        display={'flex'}
        flexDir={'column'}
        alignItems={'center'}
        padding={4}
        my={7}
        maxW={'430px'}
        marginInline="auto"
      >
        <Heading as="h1" fontSize={18} lineHeight={5} fontFamily={'Inter Variable'}>
          Get Your Card In Just 2 Steps
        </Heading>

        <Stepper index={location.pathname.includes('/kyc') ? 0 : 1} mt={6} w={'100%'} gap={0}>
          <Step key={'kyc'} data-orientation="vertical" style={{ gap: 0 }}>
            <Box display={'flex'} flexDir={'column'} alignItems={'center'}>
              <StepIndicator>
                <StepStatus
                  active={<StepNumber />}
                  complete={<StepNumber />}
                  incomplete={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0" display={'flex'} flexDir={'column'} alignItems={'center'}>
                <StepTitle style={{ fontSize: '14px' }}>Verify Identity</StepTitle>
                <StepDescription style={{ color: '#727272', fontSize: '10px' }}>
                  (Basic Info)
                </StepDescription>
              </Box>
            </Box>
            <StepSeparator
              style={{
                marginTop: '1rem',
                marginInlineStart: '0',
                backgroundImage: location.pathname.includes('/kyc')
                  ? 'none'
                  : 'linear-gradient(62deg, #4A04FF 17.47%, #9104FF 95.51%)',
              }}
            />
          </Step>
          <Step
            key={'apply-card'}
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0,
              alignSelf: 'flex-start',
            }}
          >
            <StepIndicator>
              <StepStatus
                active={<StepNumber />}
                complete={<StepNumber />}
                incomplete={<StepNumber />}
              />
            </StepIndicator>
            <Box flexShrink="0">
              <StepTitle style={{ fontSize: '14px' }}>Apply for Card</StepTitle>
            </Box>
          </Step>
        </Stepper>
      </Box>

      <Outlet />
    </Container>
  )
}
