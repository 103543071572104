import { ArrowUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  Grid,
  IconButton,
  NumberInput,
  NumberInputField,
  Text,
  Wrap,
  WrapItem,
  useBoolean,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import SvgIconDeposit from 'assets/icons/IconDeposit'
import { CopyButton } from 'components'
import NorpayButton from 'components/Button/NorpayButton'
import DepositRecoverModal from 'components/Modal/DepositRecoverModal'
import { queryKeys } from 'data/query-keys'
import { ethers } from 'ethers'
import useSNTPriceInUSDT from 'hooks/useSNTInUSDT'
import { useTreasuryContract } from 'hooks/useTreasuryContract'
import { reportErrorToSentry } from 'lib/error-reporting'
import { api } from 'lib/query-client'
import { useAuth } from 'providers/AuthProvider'
import { useGlobal } from 'providers/GlobalProvider'
import { Suspense, useMemo, useState, useTransition } from 'react'
import { InitializeDepositInput } from 'types/api'
import { DepositFees } from 'types/deposit'
import { getShortString } from 'utils/formatters'
import { useBalance } from 'wagmi'

const presetAmounts = ['50', '100', '500', '1000']

interface DepositButtonProps {
  cardTypeId: string
  cardId: string
}

const DepositButton = ({ cardTypeId, cardId }: DepositButtonProps) => {
  const { account } = useAuth()
  const { currentChain } = useGlobal()
  const token = currentChain?.tokens?.[0]

  const toast = useToast()
  const {
    isOpen: isRecoverModalOpen,
    onOpen: onRecoverModalOpen,
    onClose: onRecoverModalClose,
  } = useDisclosure()
  const [isLoading, { on: turnOnLoading, off: turnOffLoading }] = useBoolean()

  const queryClient = useQueryClient()

  const [, startTransition] = useTransition()

  const [showTopUpForm, setShowTopUpForm] = useState(false)
  const [topUpAmount, setTopUpAmount] = useState(presetAmounts[0])
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [depositReference, setDepositReference] = useState<string | undefined>(undefined)
  const [hasError, setHasError] = useState(false)

  const { mutateAsync: initialiseDeposit } = useMutation({
    mutationKey: ['initialize-deposit'],
    mutationFn: async (data: InitializeDepositInput) =>
      api
        .post('cards/deposit/initialize', {
          json: data,
        })
        .json<{
          totalAmount: string
          reference: string
        }>(),
  })

  const { mutateAsync: recharge } = useMutation({
    mutationKey: [`cards/${cardId}/recharge`, cardId],
    mutationFn: (data: { reference: string }) =>
      api.post(`cards/${cardId}/recharge`, { json: data }),
    onSuccess() {
      toast({
        title: 'Deposit successful',
        status: 'success',
        isClosable: true,
      })
    },
    onError(error) {
      reportErrorToSentry(error)
      toast({
        title: 'Deposit failed',
        status: 'error',
        isClosable: true,
      })
    },
  })

  const { data: depositFees } = useQuery<DepositFees>({
    queryKey: [`deposits/${cardTypeId}/fees`],
    enabled: !!cardTypeId,
    staleTime: 10 * 60 * 1000,
  })

  const { data: balanceData } = useBalance({
    enabled: token && account ? true : false,
    address: account,
    token: token?.address,
  })

  const { data: sntPriceInUSDTData, isPending: fetchingSNTPriceInUSDT } = useSNTPriceInUSDT()

  const sntPriceInUSDT = useMemo(
    () => (sntPriceInUSDTData ? +sntPriceInUSDTData.result[0].p : 0),
    [sntPriceInUSDTData],
  )

  const { deposit } = useTreasuryContract({
    tokenAddress: token?.address,
    treasuryAddress: currentChain?.treasuryAddress,
  })

  const availableBalance = useMemo(() => {
    if (!balanceData) {
      return undefined
    }

    return parseFloat(ethers.formatUnits(balanceData.value, token?.decimals))
  }, [balanceData, token])

  const { amount, amountInBigInt, amountInSNT, amountInSNTAsBigInt } = useMemo(() => {
    if (!sntPriceInUSDT)
      return {
        amount: 0,
        amountInBigInt: BigInt(0),
        amountInSNT: 0,
        amountInSNTAsBigInt: BigInt(0),
      }

    const parsedAmount = Math.floor(parseFloat(topUpAmount === '' ? '0' : topUpAmount) * 100) / 100

    const parsedAmountInSNT = parsedAmount / sntPriceInUSDT
    const sntAmountInBigInt = ethers.parseUnits(`${parsedAmountInSNT}`, token?.decimals)

    return {
      amount: parsedAmount,
      amountInBigInt: ethers.parseUnits(`${parsedAmount}`, token?.decimals),
      amountInSNT: parsedAmountInSNT,
      amountInSNTAsBigInt: sntAmountInBigInt,
    }
  }, [topUpAmount, sntPriceInUSDT, token])

  const applicationFee = useMemo(() => {
    if (!depositFees) {
      return 0
    }

    return Math.round(depositFees.depositFee * amountInSNT) / 100
  }, [depositFees, amountInSNT])

  const { amountToTransfer, amountToTrasferInBigInt } = useMemo(() => {
    if (amount > 0) {
      const amountWithFeesBigInt =
        amountInSNTAsBigInt + ethers.parseUnits(applicationFee.toString(), token?.decimals)
      return {
        amountToTransfer: parseFloat(
          parseFloat(ethers.formatUnits(amountWithFeesBigInt, token?.decimals)).toFixed(2),
        ),
        amountToTrasferInBigInt: amountWithFeesBigInt,
      }
    }

    return {
      amountToTransfer: 0,
      amountToTrasferInBigInt: BigInt(0),
    }
  }, [amountInSNTAsBigInt, token, applicationFee, amountInBigInt])

  const hasSufficientBalance = useMemo(() => {
    if (availableBalance === undefined) {
      return false
    }

    return availableBalance >= amountToTransfer
  }, [availableBalance, amountToTransfer])

  const isPayingSufficientAmount = useMemo(() => {
    if (amount === 0) {
      return false
    }

    return amount >= (depositFees?.minRechargeDepositAmount ?? 0)
  }, [amount, depositFees])

  const setPresetAmount = (presetAmount: string) => () => setTopUpAmount(presetAmount)

  const onChange = (value: string, _) => {
    setTopUpAmount(value)
  }

  const handleDeposit = async () => {
    if (token?.id === undefined) return

    if (!isConfirmed) {
      return toast({
        status: 'warning',
        title: 'Please confirm that provided information is valid',
      })
    }

    if (!isPayingSufficientAmount) {
      return toast({
        status: 'error',
        title: `Minimum deposit amount is ${depositFees?.minApplicationDepositAmount}`,
      })
    }

    if (!hasSufficientBalance) {
      return toast({ status: 'error', title: 'Insufficient balance' })
    }

    try {
      turnOnLoading()
      startTransition(() => {
        setHasError(false)
        setLoadingText(`Requesting deposit of ${amountToTransfer}`)
      })

      const { reference } = await initialiseDeposit({
        amount: amountInBigInt.toString(10),
        tokenId: token?.id,
        cardTypeId,
        cardId,
      })
      startTransition(() => {
        setDepositReference(reference)
        setLoadingText(`Waiting for transaction`)
      })
      await deposit(amountToTrasferInBigInt as any, reference)
      try {
        startTransition(() => {
          setLoadingText(`Confirming transaction`)
        })
        await recharge({ reference })

        await queryClient.refetchQueries({ queryKey: queryKeys.GET_CARD_BALANCE(cardId) })
        setShowTopUpForm(false)
      } catch (error) {
        console.log(error)
        reportErrorToSentry(error)
        toast({ status: 'error', title: 'Failed to confirm transaction' })
        setHasError(true)
      }
    } catch (error) {
      reportErrorToSentry(error)
      console.log(error)
      toast({ status: 'error', title: 'Something went wrong' })
    } finally {
      startTransition(() => {
        setLoadingText('')
      })
      turnOffLoading()
    }
  }

  const handleRecoverDeposit = () => {
    onRecoverModalOpen()
  }

  const onDepositClick = () => {
    if (showTopUpForm) return setShowTopUpForm(false)
    setShowTopUpForm(true)
  }

  return (
    <>
      <Button
        bgColor={showTopUpForm ? 'rgba(0, 0, 0, 0.18)' : 'rgba(128, 128, 128, 0.18)'}
        _hover={{ background: showTopUpForm ? 'rgba(0, 0, 0, 0.18)' : 'rgba(128, 128, 128, 0.18)' }}
        leftIcon={<SvgIconDeposit />}
        boxShadow={showTopUpForm ? '0px 4px 3.4px 0px rgba(0, 0, 0, 0.48) inset' : 'none'}
        color="white"
        w="full"
        py={6}
        mt={6}
        borderTopRadius={12}
        borderBottomRadius={showTopUpForm ? 0 : 12}
        aria-expanded={showTopUpForm}
        onClick={onDepositClick}
      >
        Deposit
      </Button>
      <Collapse in={showTopUpForm} animateOpacity>
        <Box bgColor="rgba(0, 0, 0, 0.18)" borderBottomRadius={12} p={4} pt={0}>
          <Box
            borderRadius={6}
            bgGradient="linear-gradient(122deg, rgba(121, 121, 121, 0.16) 30.59%, rgba(113, 113, 113, 0.07) 74.22%)"
            border="0.255px solid rgba(69, 69, 69, 0)"
            p={4}
          >
            <NumberInput value={topUpAmount} onChange={onChange}>
              <NumberInputField
                placeholder="Enter deposit amount (USDT)"
                border="none"
                borderBottom={'1px solid #2D2D2D'}
                borderBottomColor={
                  hasSufficientBalance
                    ? isPayingSufficientAmount
                      ? 'green.300'
                      : 'rgba(69, 69, 69)'
                    : 'red.300'
                }
                _hover={{
                  borderBottomColor: hasSufficientBalance
                    ? isPayingSufficientAmount
                      ? 'green.300'
                      : 'rgba(69, 69, 69)'
                    : 'red.300',
                }}
                borderRadius={0}
                pl={0}
              />
            </NumberInput>

            <Flex justify="space-between" mt={6}>
              <Text fontSize={10}>
                Min Deposit: {depositFees?.minRechargeDepositAmount.toFixed(2)} USDT
              </Text>
              <Suspense fallback={<></>}>
                <Text fontSize={10} fontWeight={500}>
                  Available {(availableBalance ?? 0).toFixed(2)} {token?.symbol}
                </Text>
              </Suspense>
            </Flex>
          </Box>

          <Wrap spacing="6px" mt={3}>
            {presetAmounts.map(presetAmount => (
              <WrapItem key={presetAmount}>
                <Button
                  bgColor="#3A3A3A"
                  _hover={{ backgroundColor: '#3A3A3A' }}
                  fontSize={14}
                  fontWeight={500}
                  color="white"
                  minW="auto"
                  w="60px"
                  h="auto"
                  py={'6px'}
                  onClick={setPresetAmount(presetAmount)}
                >
                  ${presetAmount}
                </Button>
              </WrapItem>
            ))}
          </Wrap>

          <Box w="full" mt={6}>
            <Grid gridTemplateColumns={'2fr 2fr'}>
              <Text fontWeight={700}>Fee</Text>
              <Text textAlign="right" fontWeight={700}>
                {applicationFee} {token?.symbol}
              </Text>
            </Grid>

            <Grid gridTemplateColumns={'2fr 2fr'} mt={4}>
              <Flex flexDir="column">
                <Text fontWeight={700}>Amount to transfer</Text>
                <Text color="#B3B3B3">(Fee + Deposit)</Text>
              </Flex>
              <Text textAlign="right" fontWeight={700}>
                {amountToTransfer} {token?.symbol}
              </Text>
            </Grid>
          </Box>

          {hasError && (
            <>
              <Box bg="red.600" borderRadius="xl" p={4} mt={4}>
                <Box fontSize="lg">An error has occurred</Box>
                <Box mt={3}>
                  Something went wrong while confirming transaction. Please save the following
                  deposit reference and use it to recover your deposit.
                </Box>
                <Box fontWeight="bold" mt={4}>
                  Deposit reference:{' '}
                  <CopyButton
                    text={getShortString(depositReference, 6)}
                    value={depositReference ?? ''}
                  />
                </Box>
              </Box>
            </>
          )}

          <Button
            p={0}
            fontWeight={700}
            onClick={handleRecoverDeposit}
            mt={7}
            h="unset"
            bg="transparent"
            _hover={{ bg: 'transparent' }}
          >
            <Text bgGradient="linear-gradient(180deg, #2649FF 0%, #8A16FF 100%)" bgClip="text">
              Recover Deposit
            </Text>
          </Button>

          <Checkbox
            isChecked={isConfirmed}
            onChange={e => setIsConfirmed(e.target.checked)}
            colorScheme="purple"
            mt={6}
            gap={2}
          >
            I hereby confirm that I have read and understand the policy and I can't withdraw the
            crypto back to my wallet which I topped up
          </Checkbox>

          <NorpayButton
            w="full"
            fontSize={18}
            fontWeight={600}
            fontFamily="Inter Variable"
            py={4}
            onClick={handleDeposit}
            mt={9}
            isLoading={isLoading}
            loadingText={loadingText}
          >
            Deposit
          </NorpayButton>

          <IconButton
            bgColor="rgba(255, 255, 255, 0.05)"
            _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}
            h="auto"
            icon={<ArrowUpIcon color="white" boxSize={3} />}
            aria-label="collapse deposit form"
            onClick={() => setShowTopUpForm(false)}
            p={'6px'}
            w="full"
            mt={7}
          />
        </Box>
      </Collapse>

      <DepositRecoverModal
        isOpen={isRecoverModalOpen}
        onClose={onRecoverModalClose}
        cardId={cardId}
      />
    </>
  )
}

export default DepositButton
