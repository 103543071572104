import { Box, Flex, Heading, Image, ImageProps, Link, Text } from '@chakra-ui/react'
import { CardDetails, CardDetailsUrl, CardName } from 'types/card'
import { getCardImage } from 'utils/formatters'

interface RenderCardProps extends Pick<ImageProps, 'width' | 'height'> {
  hideInfo: boolean
  cardName: CardName
  cardDetails?: CardDetails
  cardDetailsUrl?: CardDetailsUrl
  areCardDetailsVisible?: boolean
}

export default function RenderCard({
  cardName,
  hideInfo,
  width,
  height,
  cardDetails,
  cardDetailsUrl,
  areCardDetailsVisible,
}: RenderCardProps) {
  const cardImageSrc = getCardImage(cardName)

  return (
    <Box pos="relative" w="fit-content" h="100%">
      <Image
        src={cardImageSrc}
        width={width}
        height={height}
        rounded="12px"
        draggable={false}
        userSelect="none"
        boxShadow="0px 6px 34px 0px rgba(255, 255, 255, 0.13)"
      />
      {hideInfo ? (
        <Flex direction="column" ml={5} pos="absolute" bottom={4}>
          <Heading size={'2xs'} mt={1} color="#F3F3F3" lineHeight="2px">
            **** **** **** ****
          </Heading>

          <Flex gap={6} fontSize={'2xs'} mt={1} color="#F3F3F3">
            <Text fontFamily="Inter Variable">EXP **/**</Text>
            <Text fontFamily="Inter Variable">CVV ***</Text>
          </Flex>
        </Flex>
      ) : (
        <>
          {!areCardDetailsVisible ? (
            <>
              <Flex direction="column" ml={5} pos="absolute" bottom={4}>
                <Heading size={'2xs'} mt={1} color="#F3F3F3" lineHeight="2px">
                  **** **** **** ****
                </Heading>

                <Flex gap={6} fontSize={'2xs'} mt={1} color="#F3F3F3">
                  <Text fontFamily="Inter Variable">EXP **/**</Text>
                  <Text fontFamily="Inter Variable">CVV ***</Text>
                </Flex>
              </Flex>
            </>
          ) : (
            <>
              {cardDetailsUrl ? (
                <Flex
                  direction={'column'}
                  ml={5}
                  bottom={4}
                  pos="absolute"
                  justifyContent={'flex-start'}
                >
                  <Flex direction="row" fontSize={{ base: 'xs', md: 'sm' }} mt={2}>
                    <Text>Password:</Text>
                    <Text fontWeight="bold">{cardDetailsUrl.password}</Text>
                  </Flex>

                  <Link
                    mt={1}
                    bgColor={'norpayBlack'}
                    p={1}
                    rounded={'md'}
                    href={cardDetailsUrl.url}
                    target="_blank"
                  >
                    <Text fontSize={{ base: 'xs', md: 'sm' }}>Get Details</Text>
                  </Link>
                </Flex>
              ) : (
                !!cardDetails && (
                  <Flex
                    justifyContent={'flex-start'}
                    direction={'column'}
                    ml={5}
                    bottom={4}
                    pos="absolute"
                  >
                    <Text as="b" fontSize={{ base: 'sm', md: 'md' }} mt={2}>
                      {cardDetails.cardNumber.replace(/(.{4})/g, '$1 ')}
                    </Text>
                    {cardName !== CardName.PhysicalCard && (
                      <Flex gap={6} fontSize={{ base: 'sm', md: 'md' }} mt={1} fontWeight={500}>
                        <Text>EXP {cardDetails.expiration}</Text>
                        <Text>CVV {cardDetails.cvv}</Text>
                      </Flex>
                    )}
                  </Flex>
                )
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}
