import { Box, Flex, Text, Image } from '@chakra-ui/react'
import { AuthenticateButton, Footer } from 'components'
import StepsToGetNorpayCard from './components/StepsToGetNorpayCard'

const Home = () => {
  return (
    <>
      <main>
        <Box
          as="section"
          aria-label="hero section"
          bgImage={'/img/home_bg.webp'}
          backgroundPosition={{ md: 'center', base: 'bottom' }}
          paddingTop={{ base: '100px', md: '120px' }}
          height={'100vh'}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Image
            src={'/cards/norpay-mesh-card.png'}
            width={{ base: '276px', md: '452px' }}
            height={{ base: '176px', md: '288px' }}
            rounded="12px"
            draggable={false}
            userSelect="none"
            marginBottom={{ base: '24px', md: '40px' }}
          />

          <Text textAlign="center" fontSize={{ base: 24, md: 40 }} fontWeight={500}>
            Unlock Limitless Possibilities:
          </Text>

          <Text
            textAlign="center"
            fontSize={{ base: 24, md: 40 }}
            lineHeight={'40px'}
            fontWeight={500}
            marginBottom={{ base: '12px', md: '28px' }}
          >
            Starnest Crypto Card
          </Text>

          <Text
            textAlign="center"
            color={{ base: '#ffffff81', md: 'white' }}
            fontFamily="Inter Variable"
            fontSize={{ base: 12, md: 14 }}
            lineHeight={5}
            maxW={{ base: '276px', md: '452px' }}
            marginInline={'auto'}
            style={{ textWrap: 'balance' }}
          >
            Experience unparalleled convenience and security in every transaction. Join the future
            of payments today with Starnest Crypto Card.
          </Text>

          <Flex justify="center" mt={10}>
            <AuthenticateButton />
          </Flex>
        </Box>

        <Box mt="72px" />

        <StepsToGetNorpayCard />
      </main>

      <Footer />
    </>
  )
}

export default Home
