import type { SVGProps } from 'react'
const SvgIconFaqs = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.5 19.56c.774 3.802 4.138 6.667 8.167 6.667h7.5c.46 0 .833-.374.833-.834v-7.5c0-4.037-2.878-7.407-6.69-8.17-.345-4.747-4.31-8.496-9.143-8.496C5.107 1.227 1 5.334 1 10.393v8.334c0 .46.373.833.833.833zm9.776-8.138a9.174 9.174 0 0 1-8.08 8.08 6.67 6.67 0 0 0 6.47 5.058h6.667v-6.667a6.67 6.67 0 0 0-5.057-6.47m2.557 8.138h-5a.834.834 0 0 0 0 1.667h5a.834.834 0 0 0 0-1.667m-1.666-1.667h1.671a.834.834 0 0 0-.005-1.666h-1.666a.834.834 0 0 0 0 1.666m-7.82-4.14.564.563a.834.834 0 0 0 1.178-1.179l-.563-.563a4.585 4.585 0 0 0-3.776-7.18 4.585 4.585 0 0 0-4.583 4.583 4.585 4.585 0 0 0 7.18 3.775m-.031-2.389a2.918 2.918 0 1 0-5.482-1.388 2.918 2.918 0 0 0 4.303 2.566l-.726-.726a.834.834 0 0 1 1.178-1.179z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgIconFaqs
