import { Box } from '@chakra-ui/react'
import { Container } from 'components'
import { cardTypeToGradient } from 'data/cardData'
import CardActions from 'pages/Dashboard/components/CardActions/CardActions'
import UserSection from 'pages/Dashboard/components/UserSection/UserSection'
import { useAuth } from 'providers/AuthProvider'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export default function CardActionsPage() {
  const { cards } = useAuth()

  const params = useParams<{ cardId: string }>()
  const { cardId } = params

  const selectedCard = useMemo(() => cards?.find(card => card.id === cardId), [cards, cardId])

  if (!selectedCard) return null

  return (
    <Box as="main" bgGradient={cardTypeToGradient[selectedCard?.type.name ?? '']}>
      <Container pt={8} maxW="620px">
        <UserSection />
        <Box mt={8} />
        <CardActions selectedCardId={selectedCard?.id} showBackButton />
      </Container>
    </Box>
  )
}
