import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react'
import { useIsFetching, useQuery } from '@tanstack/react-query'
import { Container } from 'components'
import { queryKeys } from 'data/query-keys'
import { CardBalance, CardStatus } from 'types/card'

interface BalanceProps {
  selectedCardId: string
  isCardActivated: boolean
  cardStatus?: CardStatus
}

export default function Balance({ selectedCardId, isCardActivated, cardStatus }: BalanceProps) {
  const { data: cardBalance, isLoading: fetchingCardBalance } = useQuery<CardBalance>({
    queryKey: queryKeys.GET_CARD_BALANCE(selectedCardId),
    enabled: !!selectedCardId && isCardActivated,
    refetchInterval: 5 * 60 * 1000,
  })

  const isFetching = useIsFetching({
    queryKey: queryKeys.GET_CARD_STATUS(selectedCardId),
    exact: true,
  })

  const getWalletBalanceTitle = () => {
    if (cardStatus === 'not-applied') return 'Card Not Applied'

    if (cardStatus === 'freezing') return 'Card Frozen'
    if (cardStatus === 'unfreezing') return 'Card Frozen'
    if (cardStatus === 'frozen') return 'Card Frozen'

    if (cardStatus === 'registered') return 'Card Not Linked'

    if (cardStatus === 'pending') return 'Request started'

    if (cardStatus === 'linked') return 'KYC Pending'

    if (cardStatus === 'kyc-reviewing') return 'KYC Under Review'

    if (cardStatus === 'kyc-review-success') return 'KYC successful'

    if (cardStatus === 'kyc-review-rejected') return 'KYC Rejected'

    if (cardStatus === 'activating') return 'Activating'

    return 'Active Card Balance'
  }

  return (
    <Container maxW="400px" as="section" marginInline="auto" aria-label="balance section">
      <Button
        p={3}
        w="full"
        transition="all 0.2s"
        borderRadius="16px"
        bg="rgba(0,0,0,0.65)"
        _hover={{ bg: 'rgba(0,0,0,0.90)' }}
        _expanded={{ bg: 'rgba(0,0,0,0.45)' }}
        _focus={{ boxShadow: 'outline' }}
        flexDir="column"
        h="unset"
        justifyContent="normal"
        alignItems="normal"
      >
        <Flex gap={2} alignItems="center" mb={1}>
          <Box
            bgGradient={
              isCardActivated
                ? 'linear-gradient(180deg, #1AEA16 0%, #18B228 100%)'
                : 'linear-gradient(180deg, #EA1616 0%, #B21818 100%)'
            }
            w={3}
            h={3}
            rounded="full"
          />
          <Text>{getWalletBalanceTitle()}</Text>
        </Flex>

        <Skeleton isLoaded={!fetchingCardBalance || !isFetching}>
          <Flex justify="space-between" alignItems="center">
            <Text fontSize={{ base: 16, md: 24 }}>
              {cardBalance ? cardBalance.availableBalance.toFixed(2) : Number(0).toFixed(2)}{' '}
              {cardBalance && (
                <Text as="span" fontSize="12px" textTransform="uppercase">
                  {cardBalance.cardCurrency}
                </Text>
              )}
            </Text>
          </Flex>
        </Skeleton>
      </Button>
    </Container>
  )
}
