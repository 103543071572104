export const formatTransactionMonth = inputMonth => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const month = monthNames[parseInt(inputMonth.slice(0, 2)) - 1]
  const year = inputMonth.slice(2)

  const formattedDate = `${month} ${year}`

  return formattedDate
}
