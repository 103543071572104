import { useQuery } from '@tanstack/react-query'
import ky from 'ky'

export default function useSNTPriceInUSDT() {
  return useQuery<{
    result: {
      p: string
    }[]
  }>({
    queryKey: ['sntPriceInUSDT'],
    retry: 3,
    staleTime: 5 * 60 * 1000,
  })
}
