import { Text, Flex } from '@chakra-ui/react'

type WarningProps = {
  textColor: string
  text: string
}
const Warning = ({ text, textColor }: WarningProps) => {
  return (
    <Flex my={2} rounded={10} bgColor="gray.800">
      <Text className="warning" m={4} fontSize="m" alignSelf={'center'} textColor={textColor}>
        {text}
      </Text>
    </Flex>
  )
}
export default Warning
