import React, { useState } from 'react'
import { Button, Container, ContainerBox, CopyButton, Loader, Table } from 'components'
import { useApi } from 'hooks/useApi'
import { Box, Flex, Heading, Input } from '@chakra-ui/react'
import { UserInfo } from 'types/user'
import { DepositInfo } from 'types/deposit'
import { ethers } from 'ethers'
import { getShortString } from 'utils/formatters'
import countries from 'data/countries.json'

const UserSearch = () => {
  const { getUserInfo, getDepositsByUser } = useApi()
  const [query, setQuery] = useState<string>('')
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>()
  const [deposits, setDeposits] = useState<DepositInfo[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const getData = async () => {
    try {
      setIsLoading(true)
      const userInfoResult = await getUserInfo(query)
      if (userInfoResult.user) {
        const depositsResult = await getDepositsByUser(userInfoResult.user.id)
        setDeposits(depositsResult.sort((a, b) => b.date.localeCompare(a.date)))
      }
      setUserInfo(userInfoResult.user)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const userInfoFields = [
    { label: 'Name', value: userInfo?.name },
    { label: 'Surname', value: userInfo?.surname },
    { label: 'Email', value: userInfo?.email },
    {
      label: 'Phone',
      value:
        userInfo?.phoneCode && userInfo?.phoneNumber
          ? `+${userInfo?.phoneCode} ${userInfo?.phoneNumber}`
          : '',
    },
    { label: 'Country', value: countries.find(c => c.alpha3 === userInfo?.country)?.name },
    { label: 'Nationality', value: countries.find(c => c.alpha3 === userInfo?.nationality)?.name },
    { label: 'KYC Status', value: userInfo?.kycStatus },
  ]

  const depositColumns = [
    {
      title: 'Date',
      render: (deposit: DepositInfo) => new Date(deposit.date).toUTCString(),
    },
    {
      title: 'Amount',
      render: (deposit: DepositInfo) =>
        `${ethers.formatUnits(deposit.amount, deposit.token.decimals)} ${deposit.token.symbol}`,
    },
    {
      title: 'Fee',
      render: (deposit: DepositInfo) =>
        `${ethers.formatUnits(deposit.fee, deposit.token.decimals)} ${deposit.token.symbol}`,
    },
    {
      title: 'Reference',
      render: (deposit: DepositInfo) => (
        <CopyButton text={getShortString(deposit.reference)} value={deposit.reference} />
      ),
    },
    {
      title: 'Type',
      render: (deposit: DepositInfo) => <Box textTransform="capitalize">{deposit.type}</Box>,
    },
    {
      title: 'Status',
      render: (deposit: DepositInfo) => <Box textTransform="capitalize">{deposit.status}</Box>,
    },
    {
      title: 'Card Type',
      render: (deposit: DepositInfo) => <Box textTransform="capitalize">{deposit.cardTypeId}</Box>,
    },
  ]

  return (
    <Container mt={16}>
      <ContainerBox my={12} py={6} px={{ base: 4, lg: 12 }}>
        <Heading>User Search</Heading>
        <Box mt={5} mb={3}>
          <Input
            placeholder="Enter address or email"
            maxW="xl"
            onChange={e => setQuery(e.target.value)}
          />
        </Box>
        <Button onClick={getData}>Search</Button>
        {!isLoading && userInfo && deposits && (
          <Box mt={6} p={4} background="whiteAlpha.100" borderRadius="xl">
            <Heading size="md" mb={2}>
              User Information
            </Heading>
            {userInfoFields.map(field => (
              <Flex gap={2}>
                <Box fontWeight="bold">{field.label}:</Box>
                <Box>{field.value}</Box>
              </Flex>
            ))}
            <Heading size="md" mt={4} mb={2}>
              Deposits
            </Heading>
            <Table columns={depositColumns} data={deposits} />
          </Box>
        )}
        {!isLoading && userInfo === null && <Box mt={6}>User not found</Box>}
        {isLoading && <Loader />}
      </ContainerBox>
    </Container>
  )
}

export default UserSearch
