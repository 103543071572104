import { Flex, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

type Props = {
  name: string
  onChange: (e: any) => void
  isDisabled?: boolean
}

const DateInput = ({ name, onChange, isDisabled }: Props) => {
  const [formData, setFormData] = useState<{ day?: string; month?: string; year?: string }>({
    day: undefined,
    month: undefined,
    year: undefined,
  })

  const handleChange = event => {
    const { name: targetName, value } = event.target

    if (targetName === 'day' && (value.length > 2 || +value > 31)) return

    if (targetName === 'month' && (value.length > 2 || +value < 0 || +value > 12)) return

    if (targetName === 'year' && value.length > 4) return

    setFormData(prevData => ({
      ...prevData,
      [targetName]: value,
    }))
  }

  useEffect(() => {
    if (
      (formData.day?.length === 1 || formData.day?.length === 2) &&
      (formData.month?.length === 1 || formData.month?.length === 2) &&
      formData.year?.length === 4
    ) {
      onChange({
        target: {
          name,
          value: `${formData.year}-${
            formData.month.length === 1 ? 0 + formData.month : formData.month
          }-${formData.day.length === 1 ? 0 + formData.day : formData.day}`,
        },
      })
    } else {
      onChange({ target: { name, value: '' } })
    }
  }, [formData])

  return (
    <Flex gap={3}>
      <Input
        type="number"
        name="day"
        placeholder="DD"
        maxW="100px"
        onChange={handleChange}
        isDisabled={isDisabled}
        maxLength={2}
        value={formData.day}
      />
      <Input
        type="number"
        name="month"
        placeholder="MM"
        maxW="100px"
        onChange={handleChange}
        isDisabled={isDisabled}
        maxLength={2}
        value={formData.month}
      />
      <Input
        type="number"
        name="year"
        maxW="100px"
        placeholder="YYYY"
        onChange={handleChange}
        isDisabled={isDisabled}
        maxLength={4}
        value={formData.year}
      />
    </Flex>
  )
}

export default DateInput
