import { Box, Button, Circle, Flex, Image, Text } from '@chakra-ui/react'
import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit'
import { useAuth } from 'providers/AuthProvider'
import { truncateEthAddress } from 'utils/web3'

const ConnectButton = () => {
  const { isAuthenticated, account } = useAuth()
  return (
    <RainbowConnectButton.Custom>
      {({ chain, openAccountModal, openChainModal, authenticationStatus, mounted }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected =
          ready &&
          account &&
          chain &&
          isAuthenticated &&
          (!authenticationStatus || authenticationStatus === 'authenticated')
        return (
          <Box
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return null
              }

              if (chain.unsupported) {
                return (
                  <Button colorScheme="red" onClick={openChainModal}>
                    Wrong network
                  </Button>
                )
              }
              return (
                <Flex gap={3}>
                  <Button
                    variant="secondary"
                    background="#111"
                    color="white"
                    onClick={openChainModal}
                    gap={3}
                    display={{ base: 'none', md: 'block' }}
                  >
                    <Flex gap={3}>
                      {chain.hasIcon && (
                        <Box
                          background={chain.iconBackground}
                          height="18px"
                          width="18px"
                          borderRadius="xl"
                          overflow="hidden"
                        >
                          {chain.iconUrl && <Image src={chain.iconUrl} w="18px" h="18px" />}
                        </Box>
                      )}
                      <Text display={{ base: 'none', md: 'block' }}>{chain.name}</Text>
                    </Flex>
                  </Button>
                  <Button
                    variant="secondary"
                    background="#111"
                    color="white"
                    gap={3}
                    onClick={openAccountModal}
                    fontSize={{ base: '12px', md: '16px' }}
                    h="unset"
                    py={{ base: '8px', md: '12px' }}
                  >
                    <Circle bg="green.400" size="12px" />
                    <Text>{truncateEthAddress(account)}</Text>
                  </Button>
                </Flex>
              )
            })()}
          </Box>
        )
      }}
    </RainbowConnectButton.Custom>
  )
}

export default ConnectButton
