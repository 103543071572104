import React from 'react'
import { Container as ChakraContainer, ContainerProps } from '@chakra-ui/react'

const Container = ({ children, ...rest }: ContainerProps) => {
  return (
    <ChakraContainer maxW="1400px" {...rest}>
      {children}
    </ChakraContainer>
  )
}

export default Container
