import React from 'react'
import { Button as ChakraButton, ButtonProps, Flex, Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

interface Props extends ButtonProps {
  to?: string
  href?: string
  variant?: 'link' | 'secondary'
}

const Button = ({ children, to, href, variant, colorScheme, ...rest }: Props) => {
  const navigate = useNavigate()
  const handleClick = e => {
    e.stopPropagation()
    if (href) {
      window.open(href, '_blank')
    }
    if (to) {
      navigate(to)
    }
  }

  const content = (
    <Flex alignItems="center">
      <Box>{children}</Box>
    </Flex>
  )

  if (variant === 'link') {
    return (
      <ChakraButton
        bg="transparent"
        textColor="purple.500"
        onClick={handleClick}
        p={0}
        _hover={{ bg: 'transparent', color: 'purple.600' }}
        {...rest}
      >
        {content}
      </ChakraButton>
    )
  }

  const getBg = () => {
    if (colorScheme || variant === 'secondary') {
      return undefined
    }
    return 'linear-gradient(135deg, #7C2FDD 0%, #88A0F0 100%)'
  }

  return (
    <ChakraButton
      borderRadius="md"
      bg={getBg()}
      colorScheme={colorScheme}
      onClick={handleClick}
      _hover={{
        bg: getBg(),
      }}
      {...rest}
    >
      {content}
    </ChakraButton>
  )
}

export default Button
