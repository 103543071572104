import { Avatar, Box, Text } from '@chakra-ui/react'
import { useAuth } from 'providers/AuthProvider'
import { useNavigate } from 'react-router-dom'

export default function UserSection() {
  const { user } = useAuth()
  const navigate = useNavigate()

  const onGetHelp = () => {
    // navigate('/user-info')
  }

  return (
    <Box
      as="section"
      borderRadius={16}
      bgColor="rgba(0, 0, 0, 0.32)"
      border="1px solid rgba(255, 255, 255, 0.13)"
      display="flex"
      alignItems="center"
      px={4}
      py={3}
      gap={3}
    >
      <Avatar
        name={`${user?.name} ${user?.surname ?? ''}`}
        src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=${user?.name}${
          user?.surname ?? ''
        }&radius=50&size=28&backgroundType=solid&scale=90&flip=true`}
        w={7}
        h={7}
        borderRadius="full"
        border="1px solid #FFF"
        bgColor="lightgray"
      />

      <Text fontWeight="bold" w="full" textAlign="center">
        Welcome {user?.name} {user?.surname}
      </Text>

      {/* <IconButton
        icon={<SvgIconHelp />}
        aria-label="help"
        onClick={onGetHelp}
        w={8}
        h={8}
        minW="auto"
        height="auto"
        background="transparent"
        _hover={{ background: 'transparent' }}
      /> */}
    </Box>
  )
}
