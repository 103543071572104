import { Select, SelectProps } from '@chakra-ui/react'
import { useGlobal } from 'providers/GlobalProvider'

interface Props {
  onSelect: (countryCode: string) => void
  defaultValue?: string
  selectProps?: Omit<SelectProps, 'onChange'>
}

const CountrySelect = ({ onSelect, defaultValue, selectProps }: Props) => {
  const { countries } = useGlobal()
  return (
    <Select
      placeholder="Select Country"
      defaultValue={defaultValue}
      onChange={e => onSelect(e.target.value)}
      {...selectProps}
    >
      {countries?.map((country, index) => (
        <option key={index} value={country.alpha3}>
          {country.name}
        </option>
      ))}
    </Select>
  )
}

export default CountrySelect
