import { useQueries, useQuery } from '@tanstack/react-query'
import { createContext, useContext, useMemo } from 'react'
import { CardType } from 'types/card'
import { TreasuryChain } from 'types/chain'
import { Country } from 'types/country'
import { DepositFees } from 'types/deposit'
import { useChainId } from 'wagmi'

type GlobalInfo = {
  treasuryChains?: TreasuryChain[]
  depositFees?: DepositFees
  cardTypes?: CardType[]
  isLoading: boolean
  currentChain?: TreasuryChain
  countries?: Country[]
}

type Props = {
  children: React.ReactNode
}

const initialValue = {
  treasuryChains: undefined,
  depositFees: undefined,
  cardTypes: undefined,
  isLoading: false,
  currentChain: undefined,
}

const GlobalContext = createContext<GlobalInfo>(initialValue)

export const useGlobal = () => {
  return useContext(GlobalContext)
}

const GlobalProvider = ({ children }: Props) => {
  const chainId = useChainId()

  const { data: cardTypes, isPending: cardTypesPending } = useQuery<CardType[] | undefined>({
    queryKey: ['cards/types'],
    staleTime: 24 * 60 * 60 * 1000,
  })

  const { data: countries } = useQuery<Country[]>({
    queryKey: ['countries'],
    staleTime: Infinity,
    select(data) {
      return data
        ?.filter(country => country.supportedIdentityReport)
        .toSorted((a, b) => a.name.localeCompare(b.name))
    },
  })

  const { data: treasuryChains, isPending: treasuryChainsPending } = useQuery<
    TreasuryChain[] | undefined
  >({ queryKey: ['chains'] })

  useQueries({
    queries: (cardTypes ?? []).map(cardType => ({
      queryKey: [`deposits/${cardType.id}/fees`],
      enabled: !!cardType,
      staleTime: 10 * 60 * 1000,
    })),
  })

  const currentChain = useMemo(() => {
    if (!treasuryChains) {
      return undefined
    }

    return treasuryChains.find(chain => chain.chainId === chainId)
  }, [chainId, treasuryChains])

  const isLoading = cardTypesPending || treasuryChainsPending

  return (
    <GlobalContext.Provider
      value={{ treasuryChains, cardTypes, isLoading, currentChain, countries }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
