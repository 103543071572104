import type { SVGProps } from 'react'
const SvgWallet = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g fill="#fff" clipPath="url(#wallet_svg__a)">
      <path d="m5.607 4.862 9.567-2.612-.506-1.024a1.35 1.35 0 0 0-1.806-.609L4.297 4.862zM18.146 2.324q-.179 0-.356.049l-2.248.614L8.678 4.86h11.187000000000001l-.42-1.541a1.345 1.345 0 0 0-1.3-.995M21.27 5.809H1.35a1.345 1.345 0 0 0-1.301.994c-.03.113-.049.232-.049.353v15.015c0 .744.604 1.347 1.348 1.347h19.92c.743 0 1.347-.604 1.347-1.347v-3.76h-7.997a2.29 2.29 0 0 1-2.29-2.29v-2.998c0-.62.247-1.183.649-1.595a2.27 2.27 0 0 1 1.639-.693h7.999V7.156A1.344 1.344 0 0 0 21.27 5.81" />
      <path d="M23.561 12.119a1.35 1.35 0 0 0-.908-.353H14.62c-.744 0-1.348.603-1.348 1.347v3c0 .744.604 1.347 1.348 1.347h8.034c.154 0 .3-.027.437-.072a1.343 1.343 0 0 0 .91-1.275v-3c0-.393-.17-.75-.439-.994m-6.128 2.762a.674.674 0 0 1-.674.674h-.447a.674.674 0 0 1-.674-.674v-.447a.681.681 0 0 1 .674-.674h.447c.372 0 .674.302.674.674z" />
    </g>
    <defs>
      <clipPath id="wallet_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgWallet
