import { Button, ButtonProps } from '@chakra-ui/react'
import { motion } from 'framer-motion'

interface NorpayButtonProps extends Omit<ButtonProps, 'as'> {
  children: React.ReactNode
}

export default function NorpayButton({ children, ...buttonProps }: NorpayButtonProps) {
  return (
    <Button
      as={motion.button}
      whileTap={{ scale: 0.95 }}
      bgGradient="linear-gradient(268deg, #D846A5 0%, #4D0EAE 100%)"
      height="auto"
      _hover={{ bgGradient: 'linear-gradient(268deg, #D846A5 0%, #4D0EAE 100%)' }}
      maxW={80}
      color="white"
      w="100%"
      opacity={buttonProps.disabled ? 0.5 : 1}
      isDisabled={buttonProps.disabled}
      borderRadius={24}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}
