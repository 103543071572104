import { QueryClient } from '@tanstack/react-query'
import ky from 'ky'

export const api = ky.create({
  retry: 0,
  prefixUrl: `${process.env.REACT_APP_API_URL}/api/v1`,

  hooks: {
    beforeRequest: [
      request => {
        const userString = localStorage.getItem('user')
        const user = userString ? JSON.parse(userString) : null
        if (user) {
          request.headers.set('Authorization', `Bearer ${user.token}`)
        }
      },
    ],

    beforeError: [
      async error => {
        if (error.response.status === 401) {
          localStorage.removeItem('user')
          window.location.reload()
        } else {
          const { response } = error

          if (response && response.body) {
            const body = await response.json()

            error.message = (body as { message?: string })?.message ?? 'No message found'
            return error
          }
        }

        return error
      },
    ],
  },
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error.name === 'HTTPError' && error.message.includes('400')) {
          return false
        }
        return failureCount < 3
      },
      refetchOnWindowFocus: false,
      queryFn: ({ queryKey, signal }) => api.get(queryKey[0] as string, { signal }).json(),
    },
    mutations: {
      retry: false,
    },
  },
})
