import { Box, Checkbox, Flex, Heading, Text } from '@chakra-ui/react'
import SvgWallet from 'assets/Wallet'
import ApplicationForm from 'components/ApplicationForm'
import { useAuth } from 'providers/AuthProvider'

export default function KycForm() {
  const { account } = useAuth()
  return (
    <Box
      bgGradient={
        'linear-gradient(143deg, rgba(0, 0, 0, 0.62) 9.21%, rgba(17, 17, 17, 0.62) 34.79%, rgba(0, 0, 0, 0.62) 59.53%, rgba(4, 4, 4, 0.62) 87.66%, rgba(7, 7, 7, 0.62) 103.24%)'
      }
      borderColor="rgba(178, 178, 178, 0.18)"
      borderWidth={1}
      borderRadius={24}
      p={6}
      maxW={'520px'}
      marginInline="auto"
    >
      <Heading as="h2" fontSize={26} lineHeight={9} color="#FCFCFC" textAlign="center">
        Verify Identity
      </Heading>
      <Text
        color="rgba(252, 252, 252, 0.64)"
        fontSize={12}
        lineHeight={4}
        mt={1}
        textAlign="center"
      >
        It will take only 2 minutes
      </Text>

      <Flex display="flex" gap={1} mt={7}>
        <SvgWallet />
        <Text fontSize={18} fontWeight={500} lineHeight={6} color="#FCFCFC">
          Wallet Address
        </Text>
      </Flex>

      <Flex gap={2} mt={3} alignItems="baseline">
        <Checkbox
          isChecked
          sx={{
            '> span[data-checked]': {
              background: '#4A04FF',
              borderColor: '#4A04FF',
            },
            '> span[data-checked][data-hover]': {
              background: '#4A04FF',
              borderColor: '#4A04FF',
            },
          }}
        />
        <Text fontWeight={700} lineHeight={5} wordBreak="break-word">
          {account}
        </Text>
      </Flex>

      <Box mt={7} />

      <ApplicationForm />
    </Box>
  )
}
