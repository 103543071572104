import type { SVGProps } from 'react'
const SvgIconLegal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={24} fill="none" {...props}>
    <g fill="#fff" clipPath="url(#icon-legal_svg__a)">
      <path d="M16.934 10.997q.057 0 .114.002h.01a7 7 0 0 1 .638.04V3.214A2.717 2.717 0 0 0 14.983.5H3.208A2.717 2.717 0 0 0 .494 3.214v15.609a2.717 2.717 0 0 0 2.714 2.714h7.861a6.84 6.84 0 0 1-1.034-3.637c0-3.806 3.095-6.903 6.899-6.903m-6.707-6.066h4.021a.674.674 0 1 1 0 1.348h-4.021a.674.674 0 0 1 0-1.348m0 2.695h4.021a.674.674 0 1 1 0 1.348h-4.021a.674.674 0 0 1 0-1.348m-2.263 9.48H3.942a.674.674 0 1 1 0-1.348h4.022a.674.674 0 0 1 0 1.348m0-2.74H3.942a.674.674 0 1 1 0-1.349h4.022a.674.674 0 0 1 0 1.348m0-2.696H3.942a.674.674 0 1 1 0-1.348h4.022a.674.674 0 0 1 0 1.348m0-2.696H3.942a.674.674 0 1 1 0-1.348h4.022a.674.674 0 0 1 0 1.348m0-2.695H3.942a.674.674 0 1 1 0-1.348h4.022a.674.674 0 0 1 0 1.348m1.589 4.717c0-.372.302-.674.674-.674h1.937a.674.674 0 0 1 0 1.348h-1.937a.674.674 0 0 1-.674-.674" />
      <path d="M20.924 13.975a5.56 5.56 0 0 0-3.89-1.673h-.009l-.091-.001a5.604 5.604 0 0 0-5.596 5.6c0 1.226.39 2.39 1.125 3.368a5.55 5.55 0 0 0 4.471 2.232 5.604 5.604 0 0 0 5.596-5.6c0-1.479-.57-2.873-1.606-3.926m-1.599 3.015-2.137 2.664a.673.673 0 0 1-.921.124l-1.594-1.154a.674.674 0 1 1 .79-1.092l1.075.779 1.736-2.164a.674.674 0 0 1 1.051.843" />
    </g>
    <defs>
      <clipPath id="icon-legal_svg__a">
        <path fill="#fff" d="M0 .5h23v23H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgIconLegal
