import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

export const theme = extendTheme({
  components: {},
  config,
  colors: {
    norpayBlack: '#282828',
  },
  styles: { global: { body: { backgroundColor: '#000', textColor: 'white', color: '#fff' } } },
  fonts: {
    body: 'Satoshi',
    heading: 'Satoshi',
  },
})
