import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Loader } from 'components'
import ConnectedModal from 'components/Modal/ConnectedModal'
import { queryKeys } from 'data/query-keys'
import jwtDecode from 'jwt-decode'
import { api } from 'lib/query-client'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateChallengeInput, VerifySignatureInput } from 'types/api'
import { Card } from 'types/card'
import { User, VerificationStatus } from 'types/user'
import { useAccount, useSignMessage } from 'wagmi'
import * as Sentry from '@sentry/react'
import { reportErrorToSentry } from 'lib/error-reporting'
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertIcon,
  Button,
} from '@chakra-ui/react'

type AuthInfo = {
  account?: `0x${string}`
  user?: User
  isAuthenticated: boolean
  isAuthenticating: boolean
  authenticate: (address: { address: string }) => Promise<void>
  cards?: Card[]
}

type Props = {
  children: React.ReactNode
}

const initialValue = {
  account: undefined,
  isAuthenticated: false,
  isAuthenticating: false,
  cards: [],
  authenticate: async () => {},
}

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isWalletConnectedModalOpen: false,
  user: undefined,
  isUserDetailsFilled: false,
  settingsUpThings: false,
  showAccountSwitchMessage: false,
}

type IAuthState = {
  user?: User
  isAuthenticated: boolean
  isAuthenticating: boolean
  isWalletConnectedModalOpen: boolean
  isUserDetailsFilled: boolean
  settingsUpThings: boolean
  showAccountSwitchMessage: boolean
}

export type Action = {
  type:
    | 'LOGOUT'
    | 'SET_WALLET_CONNECTED_MODAL_OPEN'
    | 'SET_WALLET_CONNECTED_MODAL_CLOSE'
    | 'AUTHENTICATING'
    | 'AUTH_ERROR'
    | 'AUTHENTICATED'
    | 'SETTING_UP_THINGS'
    | 'THINGS_SET_UP'
    | 'SHOW_ACCOUNT_SWITCH_MESSAGE'
    | 'HIDE_ACCOUNT_SWITCH_MESSAGE'
  payload?: Partial<IAuthState>
}

const reducer = (state: IAuthState, action: Action) => {
  switch (action.type) {
    case 'LOGOUT':
      return {
        isAuthenticated: false,
        isAuthenticating: false,
        isWalletConnectedModalOpen: false,
        user: undefined,
        isUserDetailsFilled: false,
        settingsUpThings: false,
        showAccountSwitchMessage: false,
      }
    case 'AUTHENTICATING':
      return {
        ...state,
        isAuthenticating: true,
      }
    case 'AUTH_ERROR':
      return {
        ...state,
        isAuthenticating: false,
      }
    case 'AUTHENTICATED':
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        user: action.payload?.user,
        isUserDetailsFilled: action.payload?.user?.name ? true : false,
        isWalletConnectedModalOpen: true,
      }
    case 'SET_WALLET_CONNECTED_MODAL_OPEN':
      return {
        ...state,
        isWalletConnectedModalOpen: true,
      }
    case 'SET_WALLET_CONNECTED_MODAL_CLOSE':
      return {
        ...state,
        isWalletConnectedModalOpen: false,
      }
    case 'SETTING_UP_THINGS':
      return {
        ...state,
        settingsUpThings: true,
      }
    case 'THINGS_SET_UP':
      return {
        ...state,
        settingsUpThings: false,
      }
    case 'SHOW_ACCOUNT_SWITCH_MESSAGE':
      return {
        ...state,
        showAccountSwitchMessage: true,
      }
    case 'HIDE_ACCOUNT_SWITCH_MESSAGE':
      return {
        ...state,
        showAccountSwitchMessage: false,
      }
    default:
      return state
  }
}

const AuthContext = createContext<AuthInfo>(initialValue)

export const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const cancelRef = useRef<HTMLButtonElement | null>(null)

  const [state, dispatch] = useReducer(reducer, initialState)

  const closeWalletConnectedModal = () => {
    dispatch({ type: 'SET_WALLET_CONNECTED_MODAL_CLOSE' })
  }

  const hideAccountSwitchMessage = () => {
    dispatch({ type: 'HIDE_ACCOUNT_SWITCH_MESSAGE' })
  }

  const showAccountSwitchMessage = () => {
    dispatch({ type: 'SHOW_ACCOUNT_SWITCH_MESSAGE' })
  }

  const { signMessageAsync } = useSignMessage()

  const queryClient = useQueryClient()

  const { mutateAsync: createChallenge } = useMutation({
    mutationKey: ['users/auth/challenge'],
    mutationFn: (data: CreateChallengeInput) =>
      api.post('users/auth/challenge', { json: data }).json<{ challenge: string }>(),
  })

  const { mutateAsync: verifySignature } = useMutation({
    mutationKey: ['auth/verify'],
    mutationFn: (data: VerifySignatureInput) =>
      api.post('auth/verify', { json: data }).json<User>(),
  })

  const checkSignedInUser = useCallback((address: string) => {
    const userString = localStorage.getItem('user')
    if (!userString) {
      return null
    }
    const signedInUser = JSON.parse(userString)
    if (signedInUser?.address !== address) {
      return null
    }
    const token = jwtDecode<any>(signedInUser.token)
    if (!token?.exp) {
      return null
    }
    return token.exp * 1000 > Date.now() ? signedInUser : null
  }, [])

  const { data: cards, isLoading: fetchingCards } = useQuery<Card[]>({
    queryKey: queryKeys.GET_CARDS,
    enabled: Boolean(state.isAuthenticated && state.isUserDetailsFilled),
  })

  const routeToAppropriatePlace = useCallback(async () => {
    try {
      dispatch({ type: 'SETTING_UP_THINGS' })
      const [verificationResponse, cardsResponse] = await Promise.all([
        queryClient.fetchQuery<{
          status: VerificationStatus | undefined
        }>({
          queryKey: queryKeys.CHECK_VERIFICATION_STATUS,
        }),
        queryClient.fetchQuery<Card[]>({
          queryKey: queryKeys.GET_CARDS,
          staleTime: 0,
        }),
      ])

      dispatch({ type: 'THINGS_SET_UP' })
      if (
        verificationResponse.status === 'not-started' ||
        verificationResponse.status === 'pending' ||
        verificationResponse.status === 'rejected'
      ) {
        return navigate('/apply-card/kyc', { replace: true })
      }

      if (cardsResponse.length === 0) {
        return navigate('/apply-card', { replace: true })
      }

      if (cardsResponse.length > 0) {
        return navigate('/dashboard', { replace: true })
      }
    } catch (error) {
      dispatch({ type: 'THINGS_SET_UP' })
      console.error(error)
      reportErrorToSentry(error)
    }
  }, [navigate, queryClient])

  const handleConnect = useCallback(
    async ({ address }: { address?: string }) => {
      if (!address) return

      const signedInUser = checkSignedInUser(address)
      if (signedInUser) {
        dispatch({ type: 'AUTHENTICATED', payload: { user: signedInUser } })
        Sentry.setUser({ email: signedInUser?.email, id: address })
        await routeToAppropriatePlace()
        return
      }
      try {
        dispatch({ type: 'AUTHENTICATING' })
        const challengeResult = await createChallenge({ address })
        const signedMessage = await signMessageAsync({ message: challengeResult.challenge })
        const authenticatedUser = await verifySignature({ address, signature: signedMessage })
        localStorage.setItem('user', JSON.stringify(authenticatedUser))
        Sentry.setUser({ email: authenticatedUser?.email, id: address })
        dispatch({ type: 'AUTHENTICATED', payload: { user: authenticatedUser } })
        await routeToAppropriatePlace()
      } catch (e) {
        console.error(e)
        reportErrorToSentry(e)
        dispatch({ type: 'AUTH_ERROR' })
      }
    },
    [
      routeToAppropriatePlace,
      checkSignedInUser,
      createChallenge,
      signMessageAsync,
      verifySignature,
    ],
  )

  const handleDisconnect = useCallback(() => {
    localStorage.removeItem('user')
    dispatch({ type: 'LOGOUT' })
    Sentry.setUser(null)
    Sentry.endSession()
    queryClient.cancelQueries()
    queryClient.removeQueries()
    queryClient.clear()
    navigate('/', { replace: true })
  }, [queryClient, navigate])

  const { address: account } = useAccount({
    onConnect: ({ address }) => {
      return handleConnect({ address })
    },
    onDisconnect: handleDisconnect,
  })

  const { data: userProfileData } = useQuery<User>({
    queryKey: queryKeys.GET_PROFILE,
    enabled: state.isAuthenticated,
  })

  useEffect(() => {
    if (account) {
      const userString = localStorage.getItem('user')
      if (!userString) {
        return
      }

      const signedInUser = JSON.parse(userString)

      if (!signedInUser) {
        return
      }

      if (signedInUser.address !== account && !state.isAuthenticating) {
        return showAccountSwitchMessage()
      }
    }
  }, [account, state.isAuthenticating])

  const userData = useMemo(() => {
    if (!state.user || !userProfileData) {
      return undefined
    }
    return {
      ...state.user,
      ...userProfileData,
      id: state.user?.id || userProfileData?.id,
      address: state.user?.address || userProfileData?.address,
      name: state.user?.name || userProfileData?.name,
      email: state.user?.email || userProfileData?.email,
      surname: state.user?.surname || userProfileData?.surname,
      role: state.user?.role || userProfileData?.role,
    }
  }, [state.user, userProfileData])

  return (
    <AuthContext.Provider
      value={{
        account: userData?.address,
        user: userData,
        isAuthenticated: state.isAuthenticated,
        isAuthenticating: state.isAuthenticating,
        authenticate: handleConnect,
        cards: cards || [],
      }}
    >
      {state.settingsUpThings || fetchingCards ? (
        <Loader text="Setting Things up" />
      ) : (
        <>
          {children}
          <ConnectedModal
            isWalletConnectedModalOpen={state.isWalletConnectedModalOpen}
            closeWalletConnectedModal={closeWalletConnectedModal}
          />
          <AlertDialog
            isOpen={state.showAccountSwitchMessage}
            onClose={hideAccountSwitchMessage}
            leastDestructiveRef={cancelRef}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogBody>
                  It seems you have switched your account. Please disconnect and connect again to
                  continue
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={hideAccountSwitchMessage}
                    className="cursor-pointer"
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </AuthContext.Provider>
  )
}

export default AuthProvider
