import React, { useEffect, useState } from 'react'
import { Container, ContainerBox } from 'components'
import { useApi } from 'hooks/useApi'
import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import { UserStatistics } from 'types/user'
import { CardStatistics } from 'types/card'

const Statistics = () => {
  const { getUserStatistics, getCardStatistics } = useApi()
  const [userStatistics, setUserStatistics] = useState<UserStatistics | undefined>()
  const [cardStatistics, setCardStatistics] = useState<CardStatistics | undefined>()

  const getData = async () => {
    try {
      const [userStatisticsResult, cardStatisticsResult] = await Promise.all([
        getUserStatistics(),
        getCardStatistics(),
      ])
      setUserStatistics(userStatisticsResult)
      setCardStatistics(cardStatisticsResult)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const statistics = [
    { label: 'Total Users', value: userStatistics?.usersTotalCount },
    { label: 'Verified Users', value: userStatistics?.usersVerifiedCount },
    { label: 'Pending Users', value: userStatistics?.usersPendingCount },
    { label: 'Total Cards', value: cardStatistics?.cardsTotalCount },
    { label: 'Activated Cards', value: cardStatistics?.cardsActivatedCount },
    { label: 'Pending Cards', value: cardStatistics?.cardsPendingCount },
  ]

  return (
    <Container mt={16}>
      <ContainerBox my={12} py={6} px={{ base: 4, lg: 12 }}>
        <Heading>Statistics</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} gap={6} mt={6}>
          {statistics.map(stat => (
            <Box>
              <Heading>{stat.value}</Heading>
              <Box>{stat.label}</Box>
            </Box>
          ))}
        </SimpleGrid>
      </ContainerBox>
    </Container>
  )
}

export default Statistics
