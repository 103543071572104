import type { SVGProps } from 'react'
const SvgIconDeposit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#B5B5B5"
      fillRule="evenodd"
      d="M20.75 24.125H7.25a4.513 4.513 0 0 1-4.5-4.5c0-1.913 1.125-3.488 2.813-4.162v4.162c0 .45.224.9.45 1.238.224.337.787.45 1.237.45h13.5c.45 0 .9-.226 1.238-.45.337-.226.45-.788.45-1.238v-4.162c1.687.675 2.812 2.25 2.812 4.162 0 2.475-2.025 4.5-4.5 4.5m0-18v13.5H7.25v-13.5a2.257 2.257 0 0 1 2.25-2.25h9a2.257 2.257 0 0 1 2.25 2.25M13.1 13.55l-.787-.788a.883.883 0 0 0-1.238 0 .883.883 0 0 0 0 1.238l1.463 1.463a1.97 1.97 0 0 0 2.812 0L16.813 14a.883.883 0 0 0 0-1.238.883.883 0 0 0-1.238 0l-.787.788V8.825c0-.45-.338-.9-.9-.9s-.9.337-.9.9v4.725z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgIconDeposit
