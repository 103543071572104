export const queryKeys = {
  GET_PROFILE: ['users/profile'] as const,
  CHECK_VERIFICATION_STATUS: ['users/kyc/check-verification'] as const,
  GET_CARDS: ['cards'] as const,
  GET_CARD: (cardId: string) => [`cards/${cardId}`, cardId],
  GET_CARD_BALANCE: (cardId: string) => [`cards/${cardId}/balance`, cardId],
  GET_CARD_STATUS: (cardId: string) => [`cards/${cardId}/status`, cardId],
  GET_TOKEN_BALANCE: (address: string, account: `0x${string}`) => [
    `tokens/${address}/balance`,
    address,
    `account/${account}`,
    account,
  ],
  GET_DEPOSIT_BY_REFERENCE: (reference: string) => [`deposits/reference/${reference}`, reference],
}
