import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import ActivationCardApplyForm from 'pages/Dashboard/components/ActivationCardApplyForm/ActivationCardApplyForm'
import { CardType } from 'types/card'

interface ApplyActivationRequiredCardModalProps {
  isOpen: boolean
  onClose: () => void
  selectedCardType: CardType
}

export default function ApplyActivationRequiredCardModal({
  onClose,
  isOpen,
  selectedCardType,
}: ApplyActivationRequiredCardModalProps) {
  return (
    <Modal onClose={onClose} size="md" isOpen={isOpen} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        background="linear-gradient(135deg, #090909 12.06%, #1E1E1E 41.81%, #0B0B0B 70.59%, #212121 103.3%, #070707 121.42%)"
        borderRadius={6}
        borderColor="rgba(138, 138, 138, 0.18)"
        borderWidth={1}
      >
        <ModalCloseButton
          bgColor="white"
          borderRadius="100%"
          sx={{
            '> svg': {
              color: 'black',
            },
          }}
        />
        <ModalBody mt={7}>
          <ModalHeader padding={0}>
            <Text fontSize={24} fontWeight={500} textAlign="center">
              Apply Now
            </Text>

            <Divider
              filter={'drop-shadow(0px -1px 2.5px #000)'}
              borderColor={'#282828'}
              mt={9}
              mb={5}
              opacity={1}
            />
          </ModalHeader>

          <ActivationCardApplyForm selectedCardType={selectedCardType} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
