import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

const ContainerBox = ({ children, m, mt, mb, my, mx, ...rest }: BoxProps) => {
  return (
    <Box
      p="1px"
      bg="linear-gradient(135deg, #222222 50%, #626262 100%)"
      borderRadius="3xl"
      m={m}
      mt={mt}
      mb={mb}
      my={my}
      mx={mx}
    >
      <Box
        bg="linear-gradient(135deg, #121212 50%, #181818 100%)"
        p={4}
        borderRadius="3xl"
        {...rest}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ContainerBox
