import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'
import { useTransition } from 'react'

interface CardSwitcherProps {
  showPrevCardType: () => void
  showNextCardType: () => void
  children: React.ReactNode
  display?: string
}

const CardSwitcher = ({
  showNextCardType,
  showPrevCardType,
  display,
  children,
}: CardSwitcherProps) => {
  const [, startTransition] = useTransition()

  const onNext = () => {
    startTransition(() => {
      showNextCardType()
    })
  }

  const onPrev = () => {
    startTransition(() => {
      showPrevCardType()
    })
  }
  return (
    <Flex gap={8} alignItems="center" justify="center">
      <IconButton
        icon={
          <ChevronLeftIcon color="#68686B" w={{ base: 4, md: 9 }} height={{ base: 4, md: 9 }} />
        }
        aria-label="left-button"
        onClick={onPrev}
        minW="auto"
        display={display}
        background="transparent"
        _hover={{ background: 'transparent' }}
      />

      {children}

      <IconButton
        icon={
          <ChevronRightIcon color="#68686B" w={{ base: 4, md: 9 }} height={{ base: 4, md: 9 }} />
        }
        aria-label="right-button"
        onClick={onNext}
        minW="auto"
        h={4}
        background="transparent"
        _hover={{ background: 'transparent' }}
        display={display}
      />
    </Flex>
  )
}

export default CardSwitcher
