import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, List, ListIcon, ListItem, Text } from '@chakra-ui/react'
import SvgIconFaqs from 'assets/icons/IconFaqs'
import SvgIconKyc from 'assets/icons/IconKyc'
import SvgIconLegal from 'assets/icons/IconLegal'
import SvgIconUserHelp from 'assets/icons/IconUserHelp'
import { Container } from 'components'
import { useNavigate } from 'react-router-dom'

const infoItems = [
  {
    title: 'Get Help',
    icon: SvgIconUserHelp,
  },
  {
    title: 'Legal',
    icon: SvgIconLegal,
  },
  {
    title: "FAQ's",
    icon: SvgIconFaqs,
  },
  {
    title: 'KYC',
    icon: SvgIconKyc,
  },
]

export default function UserInfoPage() {
  const navigate = useNavigate()

  return (
    <Box as="main">
      <Container pt={8} maxW="620px">
        <Flex gap={4} alignItems="center">
          <IconButton
            icon={
              <ChevronLeftIcon color="#68686B" w={{ base: 4, md: 9 }} height={{ base: 4, md: 9 }} />
            }
            aria-label="left-button"
            onClick={() => navigate(-1)}
            minW="auto"
            background="transparent"
            _hover={{ background: 'transparent' }}
          />
          <Flex justify="center" w="full">
            <Text fontSize={24} fontWeight={500}>
              Help
            </Text>
          </Flex>
        </Flex>

        <List mt={8}>
          {infoItems.map(({ title, icon: Icon }) => (
            <ListItem as="button" key={title} display="flex" py={5} px={4}>
              <ListIcon as={Icon} color="#fff" boxSize={7} />
              <Text color="#fff" fontSize={18} fontWeight={500}>
                {title}
              </Text>
            </ListItem>
          ))}
        </List>
      </Container>
    </Box>
  )
}
