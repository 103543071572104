import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CountrySelect } from 'components'
import NorpayButton from 'components/Button/NorpayButton'
import CountryCodeSelect from 'components/CountryCodeSelect/CountryCodeSelect'
import { queryKeys } from 'data/query-keys'
import { reportErrorToSentry } from 'lib/error-reporting'
import { api } from 'lib/query-client'
import { useAuth } from 'providers/AuthProvider'
import { useGlobal } from 'providers/GlobalProvider'
import { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

const initialApplicationFormState = {
  name: '',
  surname: '',
  email: '',
  phoneAlphaCode: 'ARE',
  phoneNumber: '',

  country: '',
  street: '',
  buildingNumber: '',
  flatNumber: '',
  town: '',
  state: '',
  postcode: '',

  infoConfirmed: false,
}

type ApplicationFormState = typeof initialApplicationFormState

type CreateApplicantInput = Omit<ApplicationFormState, 'infoConfirmed' | 'phoneAlphaCode'> & {
  phoneCode: number
}

type ApplicationFormAction = {
  type: 'SET_FIELD'
  field: keyof ApplicationFormState
  value: string | boolean
}

type ApplicationFormReducer = React.Reducer<ApplicationFormState, ApplicationFormAction>

const applicationFormReducer: ApplicationFormReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

export default function ApplicationForm() {
  const toast = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { user } = useAuth()
  const { countries } = useGlobal()

  const [formData, dispatch] = useReducer<ApplicationFormReducer, ApplicationFormState>(
    applicationFormReducer,
    initialApplicationFormState,
    state => {
      const phoneAlphaCode =
        countries?.find(country => country.phoneCode === user?.phoneCode)?.alpha3 || 'ARE'
      return {
        ...state,
        phoneAlphaCode,
        name: user?.name ?? '',
        surname: user?.surname ?? '',
        email: user?.email ?? '',
        phoneNumber: user?.phoneNumber ?? '',
        country: user?.country ?? '',
        street: user?.street ?? '',
        buildingNumber: user?.buildingNumber ?? '',
        flatNumber: user?.flatNumber ?? '',
        town: user?.town ?? '',
        state: user?.state ?? '',
        postcode: user?.postcode ?? '',
      }
    },
  )

  const { mutate: createApplicant, isPending } = useMutation({
    mutationKey: ['createApplicant'],
    mutationFn: (data: CreateApplicantInput) =>
      api.post('users/kyc/create-applicant', {
        json: data,
      }),
  })

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = e.target
    dispatch({ type: 'SET_FIELD', field: name as keyof ApplicationFormState, value })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      !formData.name ||
      !formData.surname ||
      !formData.country ||
      !formData.email ||
      !formData.phoneAlphaCode ||
      !formData.phoneNumber ||
      !formData.street ||
      !formData.buildingNumber ||
      !formData.town ||
      !formData.state ||
      !formData.postcode
    ) {
      toast({ status: 'error', title: 'Please fill all required fields' })
      return null
    }

    if (!formData.infoConfirmed) {
      toast({ status: 'error', title: 'Please confirm your personal information' })
      return null
    }

    const { infoConfirmed, phoneAlphaCode, ...applicationData } = formData

    const phoneCode = countries?.find(
      country => country.alpha3 === formData.phoneAlphaCode,
    )?.phoneCode

    createApplicant(
      {
        ...applicationData,
        phoneCode: Number(phoneCode),
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: queryKeys.GET_PROFILE })
          await queryClient.invalidateQueries({ queryKey: queryKeys.CHECK_VERIFICATION_STATUS })

          toast({ status: 'success', title: 'Applicant created' })

          navigate('/apply-card', { replace: true })
        },
        onError(error) {
          reportErrorToSentry(error)
          toast({ status: 'error', title: error.message })
        },
      },
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isRequired>
        <FormLabel fontSize={14}>First Name</FormLabel>
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter your name"
          borderColor="#53545B"
          isDisabled={isPending}
        />
        <FormHelperText>Must match the name(s) in the identity document</FormHelperText>
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Last Name</FormLabel>
        <Input
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Enter your last name"
          borderColor="#53545B"
          isDisabled={isPending}
        />
        <FormHelperText>Must match the surname in the identity document</FormHelperText>
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Email</FormLabel>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
          borderColor="#53545B"
          isDisabled={isPending}
        />
      </FormControl>
      <FormControl isRequired mt={6}>
        <FormLabel fontSize={14}>Phone Number</FormLabel>

        <Flex gap={2} border="1px solid #53545B" borderRadius={12}>
          <CountryCodeSelect
            options={countries ?? []}
            value={formData.phoneAlphaCode}
            isDisabled={isPending}
            onChange={phoneAlphaCode =>
              dispatch({ type: 'SET_FIELD', field: 'phoneAlphaCode', value: phoneAlphaCode })
            }
          />
          <Input
            type="number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Enter your phone number"
            border="none"
            pl={0}
            isDisabled={isPending}
          />
        </Flex>
      </FormControl>

      <FormLabel mt={4} fontSize={18} fontWeight={700}>
        Address
      </FormLabel>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Country</FormLabel>
        <CountrySelect
          onSelect={countryCode =>
            dispatch({ type: 'SET_FIELD', field: 'country', value: countryCode })
          }
          selectProps={{
            borderColor: '#53545B',
            isDisabled: isPending,
          }}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Street</FormLabel>
        <Input
          type="text"
          name="street"
          value={formData.street}
          onChange={handleChange}
          placeholder="Enter your street"
          borderColor="#53545B"
          isDisabled={isPending}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Building Number</FormLabel>
        <Input
          type="text"
          name="buildingNumber"
          value={formData.buildingNumber}
          onChange={handleChange}
          placeholder="Enter your building number"
          borderColor="#53545B"
          isDisabled={isPending}
        />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel fontSize={14}>Flat Number</FormLabel>
        <Input
          type="text"
          name="flatNumber"
          value={formData.flatNumber}
          onChange={handleChange}
          placeholder="Enter your flat number"
          borderColor="#53545B"
          isDisabled={isPending}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Town/City</FormLabel>
        <Input
          type="text"
          name="town"
          value={formData.town}
          onChange={handleChange}
          placeholder="Enter your town/city"
          borderColor="#53545B"
          isDisabled={isPending}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>State</FormLabel>
        <Input
          type="text"
          name="state"
          value={formData.state}
          onChange={handleChange}
          placeholder="Enter your state"
          borderColor="#53545B"
          isDisabled={isPending}
        />
      </FormControl>

      <FormControl isRequired mt={4}>
        <FormLabel fontSize={14}>Postal Code</FormLabel>
        <Input
          type="text"
          name="postcode"
          value={formData.postcode}
          onChange={handleChange}
          placeholder="Enter your postal code"
          borderColor="#53545B"
          isDisabled={isPending}
        />
      </FormControl>

      <Checkbox
        size="lg"
        colorScheme="purple"
        mt={6}
        isChecked={formData.infoConfirmed}
        isDisabled={isPending}
        onChange={e =>
          dispatch({ type: 'SET_FIELD', field: 'infoConfirmed', value: e.target.checked })
        }
      >
        <Box fontSize="sm" ml={2}>
          I confirm that provided personal information (including email and phone number) is valid
          and relevant
        </Box>
      </Checkbox>

      <NorpayButton
        w="full"
        mt={6}
        isDisabled={!formData.infoConfirmed || isPending}
        isLoading={isPending}
        type="submit"
        py={4}
        maxW="unset"
      >
        Verify
      </NorpayButton>
    </form>
  )
}
