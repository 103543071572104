import React, { useState } from 'react'
import { Box, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Button, Modal } from 'components'
import { CreateDiscount } from 'types/discount'

interface Props {
  isOpen: boolean
  onClose: () => void
  onCreate: (data: CreateDiscount) => Promise<void>
}

const CreateDiscountModal = ({ isOpen, onClose, onCreate }: Props) => {
  const [formData, setFormData] = useState<CreateDiscount>({
    value: 0,
    code: '',
    quantity: 0,
    assignedTo: '',
    dateTo: '',
    dateFrom: '',
  })

  const isFormValid = () => {
    return (
      formData.value > 0 &&
      formData.code !== '' &&
      formData.quantity > 0 &&
      formData.assignedTo !== '' &&
      formData.dateTo !== '' &&
      formData.dateFrom !== ''
    )
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const updatedValue = e.target.type === 'number' ? parseFloat(value) : value

    await setFormData(prevData => ({
      ...prevData,
      [name]: updatedValue,
    }))
  }

  return (
    <>
      <Modal title="Create Discount" isOpen={isOpen} onClose={onClose}>
        <Box>
          <FormControl isRequired>
            <FormLabel>Value (%)</FormLabel>
            <Input type="number" name="value" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Code</FormLabel>
            <Input type="text" name="code" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Quantity</FormLabel>
            <Input type="number" name="quantity" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Assigned To</FormLabel>
            <Input type="text" name="assignedTo" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Date From</FormLabel>
            <Input type="date" name="dateFrom" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Date To</FormLabel>
            <Input type="date" name="dateTo" onChange={handleChange} />
          </FormControl>
          <Button
            mt={4}
            type="submit"
            onClick={() => onCreate(formData)}
            isDisabled={!isFormValid()}
          >
            Create Discount
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default CreateDiscountModal
