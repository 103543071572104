import { useToast } from '@chakra-ui/react'
import { DeliveryAddress } from 'components/Modal/DepositModal'

const BASE_URL = process.env.REACT_APP_API_URL

export const useApi = () => {
  const toast = useToast()

  const request = async (
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    body?: any,
    formData?: FormData,
    isDownload?: boolean,
  ) => {
    const userString = localStorage.getItem('user')
    const user = userString ? JSON.parse(userString) : null
    const headers = {
      Authorization: user ? `Bearer ${user.token}` : '',
    }
    if (!formData) {
      headers['Content-Type'] = 'application/json'
    }
    let requestBody
    if (body) {
      requestBody = JSON.stringify(body)
    } else if (formData) {
      requestBody = formData
    }
    const response = await fetch(`${BASE_URL}${path}`, {
      method,
      body: requestBody,
      headers,
    })

    if (!response.ok) {
      if (response.status === 400) {
        const err = await response.json()
        toast({
          status: 'error',
          title: err.message,
          duration: 15000,
          isClosable: true,
        })
      } else if (response.status === 401) {
        localStorage.removeItem('user')
        window.location.reload()
      } else {
        toast({
          status: 'error',
          title: 'Something went wrong',
        })
      }
      throw new Error()
    }

    if (isDownload) {
      const blob = await response.blob()
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'data.xlsx'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      return true
    }

    const result = await response.json()
    return result
  }

  const createApplicant = async applicantData =>
    request('POST', `/api/v1/users/kyc/create-applicant`, applicantData)

  const checkVerification = async () => request('GET', `/api/v1/users/kyc/check-verification`)

  const createChallenge = async (address: string) =>
    request('POST', `/api/v1/users/auth/challenge`, { address })

  const verifySignature = async (address: string, signature: string) =>
    request('POST', `/api/v1/auth/verify`, { address, signature })

  const resetApplicant = async () => request('POST', `/api/v1/users/kyc/reset`)

  const getCardTypes = async () => request('GET', `/api/v1/cards/types`)

  const getCards = async () => request('GET', `/api/v1/cards`)

  const initializeDeposit = async (
    tokenId: string,
    amount: string,
    cardTypeId: string,
    cardId?: string,
    discountCode?: string,
    type?: string,
  ) =>
    request('POST', `/api/v1/cards/deposit/initialize`, {
      tokenId,
      amount,
      cardId,
      cardTypeId: cardTypeId,
      discountCode,
      type,
    })

  const registerCard = async (formData: DeliveryAddress, cardTypeId: string, reference: string) => {
    return request('POST', `/api/v1/cards/register`, { ...formData, cardTypeId, reference })
  }
  const applyForCard = async (
    reference: string,
    cardTypeId: string,
    frontDoc: File,
    mixDoc: File,
    signatureImage: File,
    docExpirationDate: string,
    docNumber: string,
    emergencyContact: string,
    gender: string,
    nationality: string,
  ) => {
    const formData = new FormData()
    formData.append('reference', reference)
    formData.append('images', frontDoc)
    formData.append('images', mixDoc)
    formData.append('images', signatureImage)
    formData.append('docExpirationDate', docExpirationDate)
    formData.append('docNumber', docNumber)
    formData.append('emergencyContact', emergencyContact)
    formData.append('gender', gender)
    formData.append('nationality', nationality)
    return request('POST', `/api/v1/cards/apply/${cardTypeId}`, undefined, formData)
  }
  const bind = async (
    envelopeNumber: string,
    cardNumber: string,
    cardId: string,
    identifier: string,

    reference?: string,
  ) => {
    return request('POST', `/api/v1/cards/bind/${cardId}`, {
      envelopeNumber,
      cardNumber,
      identifier,
      reference,
    })
  }
  const bindKYC = async (
    cardId: string,
    frontDoc: File,
    mixDoc: File,
    signatureImage: File,
    dateOfBirth: string,
    docExpirationDate: string,
    docNumber: string,
    emergencyContact: string,
    gender: string,
    nationality: string,
  ) => {
    const formData = new FormData()
    formData.append('docNumber', docNumber)
    formData.append('images', frontDoc)
    formData.append('images', mixDoc)
    formData.append('images', signatureImage)
    formData.append('dateOfBirth', dateOfBirth)
    formData.append('docExpirationDate', docExpirationDate)
    formData.append('emergencyContact', emergencyContact)
    formData.append('gender', gender)
    formData.append('nationality', nationality)
    return request('POST', `/api/v1/cards/bind/kyc/${cardId}`, undefined, formData)
  }

  const rechargeCard = async (reference: string, cardId: string) =>
    request('POST', `/api/v1/cards/${cardId}/recharge`, { reference })

  const freezeCard = async cardId => request('POST', `/api/v1/cards/${cardId}/freeze`)

  const unfreezeCard = async cardId => request('POST', `/api/v1/cards/${cardId}/unfreeze`)

  const checkCardStatus = async cardId => request('GET', `/api/v1/cards/${cardId}/status`)

  const getCardDetails = async (cardId, signature) =>
    request('POST', `/api/v1/cards/${cardId}/details`, { signature })

  const getCardBalance = async cardId => request('GET', `/api/v1/cards/${cardId}/balance`)

  const getCardTransactions = async cardId => request('GET', `/api/v1/cards/${cardId}/transactions`)

  const getCardOperations = async cardId => request('GET', `/api/v1/cards/${cardId}/operations`)

  const getCardDeposits = async cardId => request('GET', `/api/v1/cards/${cardId}/deposits`)

  const getAvailableChains = async () => request('GET', `/api/v1/chains`)

  const getDepositFees = async (cardType: string) =>
    request('GET', `/api/v1/deposits/${cardType}/fees`)

  const getDiscounts = async () => request('GET', `/api/v1/discounts`)

  const deleteDiscount = async (id: string) => request('DELETE', `/api/v1/discounts/${id}`)

  const createDiscount = async data => request('POST', `/api/v1/discounts`, data)

  const createDiscountBatch = async data => request('POST', `/api/v1/discounts/batch`, data)

  const validateDiscount = async (code: string) =>
    request('GET', `/api/v1/discounts/validate?code=${code}`)

  const exportDiscounts = async () => {
    request('GET', `/api/v1/discounts/export`, undefined, undefined, true)
  }

  const getProfile = async () => request('GET', `/api/v1/users/profile`)

  const getUserStatistics = async () => request('GET', `/api/v1/users/statistics`)

  const getCardStatistics = async () => request('GET', `/api/v1/cards/statistics`)

  const getUserInfo = async query => request('GET', `/api/v1/users/info?query=${query}`)

  const getDepositsByUser = async userId => request('GET', `/api/v1/deposits/user/${userId}`)

  return {
    bind,
    bindKYC,
    getProfile,
    registerCard,
    createApplicant,
    checkVerification,
    createChallenge,
    verifySignature,
    resetApplicant,
    getCardTypes,
    getCards,
    applyForCard,
    rechargeCard,
    freezeCard,
    unfreezeCard,
    checkCardStatus,
    getCardDetails,
    getCardTransactions,
    getCardOperations,
    getCardBalance,
    initializeDeposit,
    getAvailableChains,
    getDepositFees,
    getCardDeposits,
    validateDiscount,
    getDiscounts,
    deleteDiscount,
    createDiscount,
    createDiscountBatch,
    exportDiscounts,
    getUserStatistics,
    getCardStatistics,
    getUserInfo,
    getDepositsByUser,
  }
}
