import { useToast } from '@chakra-ui/react'
import Button from './Button'

interface Props {
  text: string
  value: string
}

const CopyButton = ({ text, value }: Props) => {
  const toast = useToast()

  const handleClick = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value)
    }
    toast({ status: 'success', title: 'Copied to Clipboard' })
  }

  return (
    <Button variant="link" h={0} mt={-1} onClick={handleClick}>
      {text}
    </Button>
  )
}

export default CopyButton
