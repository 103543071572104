import type { SVGProps } from 'react'
const SvgWalletConnected = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={147} height={147} fill="none" {...props}>
    <path
      fill="url(#wallet-connected_svg__a)"
      d="M73.5 11.754c-34.101 0-61.748 27.646-61.748 61.748 0 34.103 27.647 61.75 61.748 61.75 34.104 0 61.75-27.647 61.75-61.75 0-34.102-27.646-61.748-61.75-61.748"
    />
    <path
      fill="#FFBE1B"
      d="M43.87 41.752a8.29 8.29 0 0 1-6.023-6.023l-.127-.506-.127.506a8.29 8.29 0 0 1-6.024 6.023l-.506.128.506.127a8.29 8.29 0 0 1 6.024 6.023l.127.506.127-.506a8.29 8.29 0 0 1 6.024-6.023l.506-.127zM105.678 44.762a6.24 6.24 0 0 1-4.534-4.533l-.095-.381-.096.38a6.24 6.24 0 0 1-4.534 4.534l-.38.096.38.095a6.23 6.23 0 0 1 4.534 4.534l.096.38.095-.38a6.24 6.24 0 0 1 4.534-4.534l.38-.095zM92.05 15.266A15.57 15.57 0 0 1 80.736 3.954l-.236-.95-.241.95a15.56 15.56 0 0 1-11.31 11.312l-.949.239.949.238a15.55 15.55 0 0 1 11.31 11.311l.24.95.237-.95a15.56 15.56 0 0 1 11.313-11.311l.951-.238z"
    />
    <path
      fill="#6857E5"
      d="M49.327 59.583c0-1.648.174-3.255.5-4.806h-5.173a7.22 7.22 0 0 0-7.219 7.043c.044.311.458 2.656 3.31 4.146 1.902.995 4.986.983 6.443.983h3.34a23.2 23.2 0 0 1-1.201-7.366M99.269 54.777H95.22c.327 1.551.501 3.158.501 4.806a23.2 23.2 0 0 1-1.202 7.367h7.826c1.543 0 2.971.485 4.144 1.308v-6.26a7.22 7.22 0 0 0-7.22-7.22"
    />
    <path
      fill="#FFBE1B"
      d="M72.525 36.383c-12.811 0-23.197 10.384-23.197 23.197 0 2.577.426 5.052 1.202 7.367h43.992a23.2 23.2 0 0 0 1.202-7.367c0-12.813-10.388-23.197-23.199-23.197"
    />
    <path
      fill="#FFE37B"
      d="M72.525 42.168c-9.617 0-17.414 7.795-17.414 17.413 0 2.634.588 5.13 1.635 7.367h31.557a17.3 17.3 0 0 0 1.634-7.367c0-9.618-7.796-17.413-17.412-17.413"
    />
    <path
      fill="#fff"
      d="M79.546 60.668q-.93-1.074-2.501-1.414.432-.191.604-.277.17-.084.603-.424 1.282-.966 1.282-2.948 0-1.644-1.136-2.948-.627-.7-1.873-1.113a7 7 0 0 0-.79-.204v-2.149H72.77v1.944h-1.987v-1.944h-2.965v1.944h-3.244v3.19h2.416v10.513h-2.416v2.112h14.142q.308-.224.551-.492 1.21-1.351 1.21-3.118 0-1.595-.931-2.672m-8.785-6.344h1.812c1.113 0 1.9.122 2.37.364q.699.36.7 1.486 0 1.124-.762 1.46-.76.34-2.332.34H70.76zm5.014 10.114q-.81.4-2.429.4h-2.585v-3.891h2.223q1.886 0 2.742.374.86.375.86 1.547t-.81 1.57"
    />
    <path
      fill="#8C7BFD"
      d="M102.346 66.95H47.189c-1.457 0-4.541.012-6.443-.983-3.06-1.6-3.314-4.182-3.314-4.182v47.61a7.22 7.22 0 0 0 7.222 7.221h57.692a7.22 7.22 0 0 0 7.221-7.221V74.17a7.22 7.22 0 0 0-7.221-7.22"
    />
    <path
      fill="#8AC9F9"
      d="M90.056 84.012a3.236 3.236 0 0 0-3.236 3.236v8.904a3.236 3.236 0 0 0 3.236 3.237h19.51V84.012z"
    />
    <path fill="#FFE37B" d="M93.096 88.488a3.211 3.211 0 1 0 0 6.422 3.211 3.211 0 0 0 0-6.422" />
    <defs>
      <linearGradient
        id="wallet-connected_svg__a"
        x1={73.501}
        x2={135}
        y1={11.754}
        y2={152.502}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5E19F0" />
        <stop offset={1} stopColor="#BA49FF" />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgWalletConnected
