import type { SVGProps } from 'react'
const SvgIconKyc = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={28} fill="none" {...props}>
    <path
      fill="#fff"
      d="M5.063 19.06v.212a1.477 1.477 0 0 0 1.477 1.476h5.484a1.477 1.477 0 0 0 1.477-1.477v-.21a3.29 3.29 0 0 0-2.388-3.238 2.742 2.742 0 1 0-3.662 0 3.29 3.29 0 0 0-2.388 3.238m4.22-7.171a1.899 1.899 0 1 1 0 3.797 1.899 1.899 0 0 1 0-3.797m-.845 4.64h1.688a2.476 2.476 0 0 1 2.531 2.532v.21a.63.63 0 0 1-.633.633H6.54a.633.633 0 0 1-.633-.633v-.21a2.476 2.476 0 0 1 2.531-2.532"
    />
    <path
      fill="#fff"
      d="M22.36 16.11a.42.42 0 0 0-.422.421v5.274a.633.633 0 0 1-.633.633H4.008a.633.633 0 0 1-.633-.633V9.57a.633.633 0 0 1 .633-.633h9.281a.422.422 0 1 0 0-.843H4.008A1.477 1.477 0 0 0 2.53 9.57v12.235a1.477 1.477 0 0 0 1.477 1.476h17.297a1.477 1.477 0 0 0 1.476-1.476V16.53a.42.42 0 0 0-.422-.422M21.99 8.22l-2.653 2.653-1.671-1.67a.422.422 0 0 0-.597.596l1.968 1.968a.42.42 0 0 0 .597 0l2.953-2.953a.422.422 0 0 0-.597-.595"
    />
    <path
      fill="#fff"
      d="M24.95 5.874a.42.42 0 0 0-.384-.376 8.5 8.5 0 0 1-2.333-.65 8.3 8.3 0 0 1-2.12-1.284.42.42 0 0 0-.57 0 8.3 8.3 0 0 1-2.12 1.284 8.5 8.5 0 0 1-2.32.65.42.42 0 0 0-.386.375c-.019.18-.44 4.392.602 6.472 1.246 2.485 4.218 3.88 4.343 3.938a.42.42 0 0 0 .354 0c.125-.059 3.097-1.453 4.343-3.938 1.032-2.08.61-6.292.591-6.472m-1.356 6.094c-.947 1.892-3.133 3.13-3.766 3.461-.632-.331-2.818-1.57-3.765-3.462-.776-1.552-.62-4.66-.544-5.676a10 10 0 0 0 2.236-.666 9.6 9.6 0 0 0 2.073-1.203c.639.486 1.335.89 2.074 1.203a10 10 0 0 0 2.236.666c.078 1.017.234 4.124-.544 5.676M15.61 20.75h4.218a.421.421 0 1 0 0-.844H15.61a.422.422 0 0 0 0 .844M15.61 18.219h2.53a.422.422 0 0 0 0-.844h-2.53a.421.421 0 1 0 0 .844"
    />
  </svg>
)
export default SvgIconKyc
