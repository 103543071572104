import React, { useState } from 'react'
import { Box, FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react'
import { Button, Modal } from 'components'
import { CreateDiscountBatch } from 'types/discount'

interface Props {
  isOpen: boolean
  onClose: () => void
  onCreate: (data: CreateDiscountBatch) => Promise<void>
}

const CreateDiscountBatchModal = ({ isOpen, onClose, onCreate }: Props) => {
  const [formData, setFormData] = useState<CreateDiscountBatch>({
    value: 0,
    prefix: '',
    quantity: 1,
    assignedTo: '',
    dateTo: '',
    dateFrom: '',
    batchSize: 0,
  })

  const isFormValid = () => {
    return (
      formData.value > 0 &&
      formData.prefix !== '' &&
      formData.quantity > 0 &&
      formData.assignedTo !== '' &&
      formData.dateTo !== '' &&
      formData.dateFrom !== '' &&
      formData.batchSize > 0
    )
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const updatedValue = e.target.type === 'number' ? parseFloat(value) : value

    await setFormData(prevData => ({
      ...prevData,
      [name]: updatedValue,
    }))
  }

  return (
    <>
      <Modal title="Create Discount Batch" isOpen={isOpen} onClose={onClose}>
        <Box>
          <FormControl isRequired>
            <FormLabel>Batch Size</FormLabel>
            <Input type="number" name="batchSize" onChange={handleChange} />
            <FormHelperText>Huge values (more than 1000) are not recommended</FormHelperText>
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Value (%)</FormLabel>
            <Input type="number" name="value" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Code Prefix</FormLabel>
            <Input type="text" name="prefix" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Quantity of Each Discount</FormLabel>
            <Input
              type="number"
              name="quantity"
              onChange={handleChange}
              value={formData.quantity}
            />
            <FormHelperText>Recommended value for unique discounts is 1</FormHelperText>
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Assigned To</FormLabel>
            <Input type="text" name="assignedTo" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Date From</FormLabel>
            <Input type="date" name="dateFrom" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Date To</FormLabel>
            <Input type="date" name="dateTo" onChange={handleChange} />
          </FormControl>
          <Button
            mt={4}
            type="submit"
            onClick={() => onCreate(formData)}
            isDisabled={!isFormValid()}
          >
            Create Discount Batch
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default CreateDiscountBatchModal
