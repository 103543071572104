import {
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
  Spinner,
  Switch,
  Text,
  useBoolean,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import CardStatusUpdateImage from 'assets/icons/cardStatusUpdate.png'
import SvgIconKyc from 'assets/icons/IconKyc'
import SvgIconLink from 'assets/icons/IconLink'
import { Container, Warning } from 'components'
import NorpayButton from 'components/Button/NorpayButton'
import RenderCard from 'components/cards/RenderCard'
import { cardTypeToDisplayName, cardTypeToGradient } from 'data/cardData'
import { queryKeys } from 'data/query-keys'
import { useApi } from 'hooks/useApi'
import { api } from 'lib/query-client'
import { useAuth } from 'providers/AuthProvider'
import { useGlobal } from 'providers/GlobalProvider'
import { lazy, Suspense, useMemo, useState } from 'react'
import { CardDetails, CardDetailsUrl, CardName, CardStatus, CardTypeStatus } from 'types/card'
import { useSignMessage } from 'wagmi'
import { DepositButton } from './components'
import Balance from './components/Balance/Balance'
import CardSwitcher from './components/CardSwitcher/CardSwitcher'
import UserSection from './components/UserSection/UserSection'
import { reportErrorToSentry } from 'lib/error-reporting'

const ActivationCardApplyForm = lazy(
  () => import('./components/ActivationCardApplyForm/ActivationCardApplyForm'),
)

const KYCModal = lazy(() => import('components/Modal/KYCModal'))

const ApplyCardModal = lazy(() => import('components/Modal/ApplyCardModal'))

const LinkCardModal = lazy(() => import('components/Modal/LinkCardModal'))

const Transactions = lazy(() => import('./components/Transactions/Transactions'))

const CardActions = lazy(() => import('./components/CardActions/CardActions'))

const Dashboard = () => {
  const { signMessageAsync } = useSignMessage()

  const { cards, account } = useAuth()
  const { cardTypes } = useGlobal()
  const { createChallenge } = useApi()

  const queryClient = useQueryClient()

  const toast = useToast()
  const { isOpen: isOpenBind, onOpen: onOpenBind, onClose: onCloseBind } = useDisclosure()
  const {
    isOpen: isOpenApplyCardModal,
    onOpen: openApplyCardModal,
    onClose: closeApplyCardModal,
  } = useDisclosure()
  const desktopSwitcherDisplay = useBreakpointValue({ base: 'none', md: 'inline-flex' })
  const mobileSwitcherDisplay = useBreakpointValue({ base: 'inline-flex', md: 'none' })
  const [isLoading, { on: turnOnLoading, off: turnOffLoading }] = useBoolean()

  const [cardTypeIndex, setCardTypeIndex] = useState<number>(0)

  const [cardDetails, setCardDetails] = useState<CardDetails>()
  const [cardDetailsUrl, setCardDetailsUrl] = useState<CardDetailsUrl>()
  const [isCardDetailsVisible, setIsCardDetailsVisible] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  const selectedCardType = useMemo(() => cardTypes?.[cardTypeIndex], [cardTypeIndex, cardTypes])

  const isSelectedCardTypeDisabled = useMemo(
    () => selectedCardType?.status === CardTypeStatus.disabled,
    [selectedCardType],
  )

  const selectedCard = useMemo(
    () => cards?.find(card => card.type.id === cardTypes?.[cardTypeIndex]?.id),
    [cardTypeIndex, cardTypes, cards],
  )

  const { data: cardStatus, isLoading: fetchingCardStatus } = useQuery<{ status: CardStatus }>({
    queryKey: queryKeys.GET_CARD_STATUS(selectedCard?.id ?? ''),
    enabled: Boolean(selectedCard && selectedCard.id),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
  })

  const { mutateAsync: getCardDetails } = useMutation({
    mutationKey: [`cards/${selectedCard?.id}/details`, selectedCard?.id],
    mutationFn: (signature: string) =>
      api
        .post(`cards/${selectedCard?.id}/details`, { json: { signature } })
        .json<CardDetails | CardDetailsUrl>(),
  })

  const isCardNotApplied = cardStatus?.status === 'not-applied' || selectedCard === undefined
  const isActivationRequired = useMemo(
    () => selectedCardType?.applicationType === 'activation',
    [selectedCardType],
  )

  const resetCardDetails = () => {
    setCardDetails(undefined)
    setCardDetailsUrl(undefined)
    setIsCardDetailsVisible(false)
  }

  const showNextCardType = () => {
    if (!cardTypes) return

    const nextCardTypeIndex = cardTypeIndex + 1
    const isLastCard = nextCardTypeIndex === cardTypes.length

    setCardTypeIndex(isLastCard ? 0 : nextCardTypeIndex)
    resetCardDetails()
  }

  const showPrevCardType = () => {
    if (!cardTypes) return

    const prevCardTypeIndex = cardTypeIndex - 1
    const isFirstCard = prevCardTypeIndex < 0

    setCardTypeIndex(isFirstCard ? cardTypes.length - 1 : prevCardTypeIndex)
    resetCardDetails()
  }

  const viewCardDetails = async () => {
    if (!selectedCard || !account) return

    if (cardDetails || cardDetailsUrl) {
      if (isCardDetailsVisible) {
        setIsCardDetailsVisible(false)
        return
      }
      setIsCardDetailsVisible(true)
      return
    }

    try {
      turnOnLoading()
      const challengeResult = await createChallenge(account)
      const signedMessage = await signMessageAsync({ message: challengeResult.challenge })
      const details = await getCardDetails(signedMessage)

      if ('url' in details) {
        setCardDetailsUrl(details)
      } else {
        setCardDetails(details)
      }
      setIsCardDetailsVisible(true)
    } catch (error) {
      console.error(error)
      reportErrorToSentry(error)
    } finally {
      turnOffLoading()
    }
  }

  const onApplyNow = async () => {
    if (selectedCardType?.applicationType === 'activation') {
      return
    }

    openApplyCardModal()
  }

  return (
    <Box as="main" bgGradient={cardTypeToGradient[selectedCardType?.name ?? '']}>
      <Container
        pt={8}
        bgGradient={cardTypeToGradient[selectedCardType?.name ?? '']}
        maxW="620px"
        transition="background 0.5s ease"
      >
        <UserSection />

        <Box
          as="section"
          borderRadius={24}
          border="1px solid rgba(138, 138, 138, 0.18)"
          bgGradient="linear-gradient(136deg, rgba(9, 9, 9, 0.50) 11.6%, rgba(30, 30, 30, 0.50) 35.49%, rgba(11, 11, 11, 0.50) 58.61%, rgba(33, 33, 33, 0.50) 84.88%, rgba(7, 7, 7, 0.50) 99.43%)"
          py={5}
          px={{ md: 7, base: 2 }}
          mt={5}
        >
          {(cardTypes?.length ?? 0) > 1 ? (
            <CardSwitcher
              showNextCardType={showNextCardType}
              showPrevCardType={showPrevCardType}
              display={mobileSwitcherDisplay}
            >
              <Text fontSize={16} lineHeight={'18px'}>
                {cardTypeToDisplayName[selectedCardType?.name ?? '']}
              </Text>
            </CardSwitcher>
          ) : (
            <Flex gap={8} alignItems="center" justify="center">
              <Text fontSize={16} lineHeight={'18px'}>
                {cardTypeToDisplayName[selectedCardType?.name ?? '']}
              </Text>
            </Flex>
          )}

          <Box mt={6} />
          {selectedCard && (
            <>
              <Balance
                selectedCardId={selectedCard.id}
                isCardActivated={cardStatus?.status === 'activated'}
                cardStatus={cardStatus?.status}
              />
              <Box mt={6} />
            </>
          )}

          {(cardTypes?.length ?? 0) > 1 ? (
            <CardSwitcher
              showNextCardType={showNextCardType}
              showPrevCardType={showPrevCardType}
              display={desktopSwitcherDisplay}
            >
              <Container maxW="430px">
                <RenderCard
                  cardName={selectedCardType?.name as CardName}
                  cardDetails={cardDetails}
                  cardDetailsUrl={cardDetailsUrl}
                  areCardDetailsVisible={isCardDetailsVisible}
                  hideInfo={isCardNotApplied || cardStatus?.status !== 'activated'}
                />
              </Container>
            </CardSwitcher>
          ) : (
            <Container maxW="430px">
              <RenderCard
                cardName={selectedCardType?.name as CardName}
                cardDetails={cardDetails}
                cardDetailsUrl={cardDetailsUrl}
                areCardDetailsVisible={isCardDetailsVisible}
                hideInfo={isCardNotApplied || cardStatus?.status !== 'activated'}
              />
            </Container>
          )}

          {selectedCard && (
            <Container maxW="400px">
              {fetchingCardStatus ? (
                <Skeleton h={12} rounded={'xl'} mt={7} />
              ) : isSelectedCardTypeDisabled ? (
                <>
                  <Box mt={4} />
                  <Warning
                    textColor="orange.300"
                    text={
                      'This Card is currently under maintenance. Please select a different card'
                    }
                  />
                </>
              ) : (
                <>
                  {cardStatus?.status === 'registered' && (
                    <Flex
                      mt={4}
                      borderRadius={12}
                      border="1px solid #3E3E3E"
                      bgGradient="linear-gradient(142deg, rgba(47, 47, 47, 0.18) 10.17%, rgba(170, 170, 170, 0.18) 51.93%, rgba(40, 40, 40, 0.05) 86.68%, rgba(128, 128, 128, 0.00) 112.27%)"
                      p={5}
                      alignItems="center"
                      gap={6}
                    >
                      <Image src={CardStatusUpdateImage} alt="card status" w={10} h={10} />

                      <VStack alignItems="flex-start">
                        <Text fontSize={18} fontWeight={700} lineHeight={5}>
                          Congratulations!
                        </Text>
                        <Text fontSize={12} fontWeight={500} lineHeight={4}>
                          You Have Successfully Applied For NorPay Physical Card. The Card will be
                          delivered to your address within 7-8 days.
                        </Text>
                      </VStack>
                    </Flex>
                  )}

                  {cardStatus?.status === 'linked' && (
                    <Flex
                      mt={4}
                      borderRadius={12}
                      border="1px solid #7F1AFF"
                      bgGradient="linear-gradient(142deg, rgba(47, 47, 47, 0.18) 10.17%, rgba(170, 170, 170, 0.18) 51.93%, rgba(40, 40, 40, 0.05) 86.68%, rgba(128, 128, 128, 0.00) 112.27%)"
                      p={5}
                      alignItems="center"
                      gap={6}
                    >
                      <Image src={CardStatusUpdateImage} alt="card status" w={10} h={10} />

                      <VStack alignItems="flex-start">
                        <Text fontSize={18} fontWeight={700} lineHeight={5}>
                          Card Linked Successfully
                        </Text>
                      </VStack>
                    </Flex>
                  )}

                  {cardStatus?.status === 'kyc-reviewing' && (
                    <Flex
                      mt={4}
                      borderRadius={12}
                      border="1px solid #7F1AFF"
                      bgGradient="linear-gradient(142deg, rgba(47, 47, 47, 0.18) 10.17%, rgba(170, 170, 170, 0.18) 51.93%, rgba(40, 40, 40, 0.05) 86.68%, rgba(128, 128, 128, 0.00) 112.27%)"
                      p={5}
                      alignItems="center"
                      gap={6}
                    >
                      <Image src={CardStatusUpdateImage} alt="card status" w={10} h={10} />

                      <VStack alignItems="flex-start">
                        <Text fontSize={18} fontWeight={700} lineHeight={5}>
                          KYC Under Review
                        </Text>
                      </VStack>
                    </Flex>
                  )}

                  {cardStatus?.status === 'kyc-review-success' && (
                    <Flex
                      mt={4}
                      borderRadius={12}
                      border="1px solid #7F1AFF"
                      bgGradient="linear-gradient(142deg, rgba(47, 47, 47, 0.18) 10.17%, rgba(170, 170, 170, 0.18) 51.93%, rgba(40, 40, 40, 0.05) 86.68%, rgba(128, 128, 128, 0.00) 112.27%)"
                      p={5}
                      alignItems="center"
                      gap={6}
                    >
                      <Image src={CardStatusUpdateImage} alt="card status" w={10} h={10} />

                      <VStack alignItems="flex-start">
                        <Text fontSize={18} fontWeight={700} lineHeight={5}>
                          KYC Review successful. Card Activation in progress
                        </Text>
                      </VStack>
                    </Flex>
                  )}

                  {(cardStatus?.status === 'linked' ||
                    cardStatus?.status === 'kyc-review-rejected') &&
                    isActivationRequired && (
                      <Suspense fallback={<Spinner />}>
                        <Button
                          leftIcon={<SvgIconKyc />}
                          bgColor="#6507FFCC"
                          _hover={{ backgroundColor: '#6507FFCC' }}
                          borderRadius={12}
                          w="full"
                          py={4}
                          mt={4}
                          fontWeight={500}
                          color="white"
                          h="auto"
                          minW="unset"
                          onClick={onOpenBind}
                        >
                          {cardStatus.status === 'linked' ? 'Complete KYC' : 'Re-Submit KYC'}
                        </Button>

                        <KYCModal
                          isOpen={isOpenBind}
                          onClose={onCloseBind}
                          cardId={selectedCard?.id ?? ''}
                        />
                      </Suspense>
                    )}

                  {cardStatus?.status === 'registered' && selectedCard && (
                    <Suspense fallback={<Spinner />}>
                      <Button
                        leftIcon={<SvgIconLink />}
                        bgColor="#6507FFCC"
                        _hover={{ backgroundColor: '#6507FFCC' }}
                        borderRadius={12}
                        w="full"
                        py={4}
                        mt={4}
                        fontWeight={500}
                        color="white"
                        h="auto"
                        minW="unset"
                        onClick={onOpenBind}
                      >
                        Link Card
                      </Button>

                      <LinkCardModal
                        isOpen={isOpenBind}
                        onClose={onCloseBind}
                        cardId={selectedCard?.id ?? ''}
                      />
                    </Suspense>
                  )}

                  {selectedCardType &&
                    selectedCard &&
                    cardStatus?.status === 'activated' &&
                    !isSelectedCardTypeDisabled && (
                      <>
                        <DepositButton cardTypeId={selectedCardType.id} cardId={selectedCard.id} />

                        <Flex
                          border={'1px solid #2C2C2C'}
                          rounded={24}
                          py={4}
                          px={{ md: 8, base: 3 }}
                          justify="space-between"
                          align="center"
                          mt={5}
                        >
                          <Text fontWeight={500}>
                            <Text
                              as="span"
                              sx={{
                                '&': {
                                  viewTransitionName: 'swap',
                                },
                              }}
                            >
                              {isCardDetailsVisible ? 'Hide' : 'Show'}
                            </Text>{' '}
                            Card Details
                          </Text>

                          <Skeleton isLoaded={!isLoading}>
                            <Switch
                              size="lg"
                              isChecked={isCardDetailsVisible}
                              onChange={viewCardDetails}
                              className="card-details-switch"
                              data-checked={isCardDetailsVisible}
                              aria-checked={isCardDetailsVisible}
                            />
                          </Skeleton>
                        </Flex>
                      </>
                    )}
                </>
              )}
            </Container>
          )}

          {isSelectedCardTypeDisabled ? (
            <Container maxW="400px">
              <Box mt={4} />
              <Warning
                textColor="orange.300"
                text={'This Card is currently under maintenance. Please select a different card'}
              />
            </Container>
          ) : (
            <Container maxW="400px">
              {isCardNotApplied && selectedCardType && !isActivationRequired && (
                <Suspense fallback={<Spinner />}>
                  <NorpayButton
                    w="full"
                    mt={6}
                    py={4}
                    maxW="auto"
                    onClick={onApplyNow}
                    isLoading={isLoading}
                    loadingText={loadingText}
                  >
                    Apply Now
                  </NorpayButton>

                  <ApplyCardModal
                    isOpen={isOpenApplyCardModal}
                    onClose={closeApplyCardModal}
                    selectedCardName={selectedCardType.name}
                  />
                </Suspense>
              )}

              {isCardNotApplied && selectedCardType && isActivationRequired && (
                <Suspense fallback={<Spinner />}>
                  <Box mt={9} />
                  <ActivationCardApplyForm selectedCardType={selectedCardType} />
                </Suspense>
              )}
            </Container>
          )}
        </Box>

        {selectedCard && cardStatus?.status === 'activated' && (
          <Suspense fallback={<></>}>
            <Box mt={8} />
            <Transactions selectedCardId={selectedCard?.id} />
            <Box mt={8} />
            <CardActions selectedCardId={selectedCard?.id} />
          </Suspense>
        )}
      </Container>
    </Box>
  )
}

export default Dashboard
