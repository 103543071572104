import type { SVGProps } from 'react'
const SvgIconUserHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={22} fill="none" {...props}>
    <g fill="#fff" clipPath="url(#icon-user-help_svg__a)">
      <path d="M10.067 14.535c-.594 0-1.075.495-1.075 1.089 0 .58.467 1.089 1.075 1.089s1.089-.51 1.089-1.089c0-.594-.495-1.089-1.09-1.089M10.25 6.293c-1.908 0-2.785 1.131-2.785 1.895 0 .551.466.806.848.806.764 0 .453-1.089 1.895-1.089.707 0 1.273.311 1.273.962 0 .763-.792 1.202-1.259 1.598-.41.353-.947.933-.947 2.15 0 .735.198.947.778.947.693 0 .834-.311.834-.58 0-.735.014-1.16.792-1.768.382-.297 1.584-1.258 1.584-2.588 0-1.329-1.202-2.333-3.012-2.333" />
      <path d="M10.386.613A10.38 10.38 0 0 0 0 11v9.574c0 .448.363.812.811.812h9.575a10.38 10.38 0 0 0 10.386-10.386A10.38 10.38 0 0 0 10.386.613m0 19.149H1.623v-8.763a8.76 8.76 0 0 1 8.763-8.763A8.76 8.76 0 0 1 19.149 11a8.76 8.76 0 0 1-8.763 8.763" />
    </g>
    <defs>
      <clipPath id="icon-user-help_svg__a">
        <path fill="#fff" d="M0 .613h20.771v20.771H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgIconUserHelp
