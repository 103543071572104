import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, IconButton, SkeletonText, Text, VStack } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import SvgIconTransactions from 'assets/icons/IconTransactions'
import dayjs from 'dayjs'
import { useGlobal } from 'providers/GlobalProvider'
import { useNavigate } from 'react-router-dom'
import { TransactionMonth } from 'types/card'
import { formatTransactionMonth } from './dateFormatter'

interface TransactionsProps {
  selectedCardId: string
  showBackButton?: boolean
}

const Transactions = ({ selectedCardId, showBackButton }: TransactionsProps) => {
  const { currentChain } = useGlobal()

  const navigate = useNavigate()

  const token = currentChain?.tokens?.[0]

  const {
    data: cardTransactions,
    isLoading,
    isFetched,
  } = useQuery<TransactionMonth[]>({
    queryKey: [`cards/${selectedCardId}/transactions`, selectedCardId],
    enabled: Boolean(selectedCardId),
    staleTime: 5 * 60 * 1000,
  })

  return (
    <Box
      bgGradient="linear-gradient(136deg, rgba(9, 9, 9, 0.32) 11.6%, rgba(11, 11, 11, 0.32) 58.61%, rgba(7, 7, 7, 0.32) 99.43%)"
      border="1px solid rgba(138, 138, 138, 0.18)"
      borderRadius={24}
      py={10}
      as="section"
      aria-label="card transactions"
    >
      <Flex as="header" justify="space-between" px={{ base: 4, md: 6 }}>
        <Flex alignItems="center" gap={1}>
          {showBackButton && (
            <IconButton
              icon={<ArrowBackIcon />}
              aria-label="go back"
              onClick={() => navigate(-1)}
            />
          )}
          <SvgIconTransactions />
          <Heading fontSize={24} fontWeight={500} as="h1">
            Transactions
          </Heading>
        </Flex>
        {/* <Button
          bg="transparent"
          _hover={{ backgroundColor: 'transparent' }}
          minW="auto"
          h="auto"
          p={0}
          fontFamily="Inter Variable"
          color="#C6C6C6"
          display={location.pathname === `/cards/${selectedCardId}/transactions` ? 'none' : 'block'}
          onClick={() => navigate(`/cards/${selectedCardId}/transactions`)}
        >
          View All
        </Button> */}
      </Flex>
      {isLoading && (
        <VStack spacing={4} mt={5} px={{ base: 4, md: 6 }}>
          {Array(5)
            .fill('')
            .map((_, i) => (
              <Flex
                key={i}
                w="full"
                borderBottom="0.269px solid rgba(69, 69, 69)"
                borderRadius="6px"
                bgGradient="linear-gradient(122deg, rgba(8, 8, 8, 0.17) 30.59%, rgba(22, 22, 22, 0.00) 74.22%)"
                py={3}
                px={{ base: 2, md: 5 }}
                justify="space-between"
              >
                <VStack spacing={1} alignItems="flex-start">
                  <SkeletonText noOfLines={1} w="100px" />
                  <SkeletonText noOfLines={1} w="100px" />
                </VStack>

                <VStack spacing={1} alignItems="flex-end">
                  <SkeletonText noOfLines={1} w="100px" />
                  <SkeletonText noOfLines={1} w="100px" />
                </VStack>
              </Flex>
            ))}
        </VStack>
      )}

      {isFetched &&
        cardTransactions?.map(month => (
          <Box key={month.month} mt={6}>
            <Box pl={6} bgColor="rgba(11, 11, 11, 0.72)" py="2px">
              <Text color="#FAFAFA" fontSize={12}>
                {formatTransactionMonth(month.month)}
              </Text>
            </Box>

            <VStack spacing={4} mt={5} px={{ base: 4, md: 6 }}>
              {month.transactions.map(transaction => (
                <Flex
                  key={transaction.transactionDate}
                  w="full"
                  borderBottom="0.269px solid rgba(69, 69, 69)"
                  borderRadius="6px"
                  bgGradient="linear-gradient(122deg, rgba(8, 8, 8, 0.17) 30.59%, rgba(22, 22, 22, 0.00) 74.22%)"
                  py={3}
                  px={{ base: 2, md: 5 }}
                  justify="space-between"
                >
                  <VStack spacing={1} alignItems="flex-start">
                    <Text fontWeight={500}>{transaction.description}</Text>
                    <Text fontSize={12} data-date={transaction.transactionDate}>
                      {dayjs.unix(+transaction.transactionDate).format('MM/DD/YY hh:mm')}
                    </Text>
                  </VStack>

                  <VStack spacing={1} alignItems="flex-end">
                    <Text
                      fontWeight={700}
                      textColor={transaction.credit >= transaction.debit ? 'green.300' : 'red.300'}
                      textAlign="right"
                    >
                      {(Number(transaction.credit) - Number(transaction.debit)).toFixed(2)} USD
                    </Text>
                    {transaction.fee && (
                      <Text fontSize={12}>Fee {Number(transaction.fee).toFixed(2)} USD</Text>
                    )}
                  </VStack>
                </Flex>
              ))}
            </VStack>
          </Box>
        ))}
    </Box>
  )
}

export default Transactions
